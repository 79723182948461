import styled from "styled-components";
import { Col, Container, Image, Row } from "react-bootstrap";

interface Props {
  bedrooms: number;
  bathrooms: number;
  living_area: number;
  address: string | null;
  property_type: string | null;
  postal_code: string | null;
  city: string | null;
}

const Wrapper = styled.div`
  background: #e4e4e5;
  border-radius: 8px;
  height: 90px;
  overflow: hidden;
  position: relative;
`;

const Address = styled.div`
  color: #333333;
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px;
`;
const MiddleRow = styled.div`
  color: #000000;
  font-size: 14px;

  span {
    float: right;
  }
`;

const ListingProps = styled.div`
  margin-top: 3px;
  span {
    color: #656f78;
    border-left: 1px solid #656f78;
    padding: 0px 5px;
  }
  span:first-child {
    padding-left: 0px;
    border: none;
  }
  span.last {
    float: right;
    padding: 0px;
    border: none;
  }
`;

const ImageWrapper = styled(Image)`
  width: 125px;
  height: 90px;
  object-fit: cover;
`;

const ListingInfo = styled.div`
  margin: 20px 0px;
  color: #625f57;
`;

const ListingInfoCol = styled(Col)`
  border-right: 1px solid #dcdcea;

  &.no-border {
    border: none;
  }
`;

const ListingInfoTitle = styled.div`
  color: #8c8c8c;
  font-weight: lighter;
  text-transform: uppercase;
`;

export function ListingDetailsCustom(props: Props) {
  const address = props.address?.split(',')[0]
  return (
    <>
      <Wrapper>
        <Container>
          <Row>
            <Col style={{ flex: "0 0 125px", paddingLeft: 0 }}>
              <ImageWrapper src='/images/not-found.jpeg' />
            </Col>
            <Col>
              <Address>{address}</Address>
              <MiddleRow>
                {props.city}, {props.postal_code}
              </MiddleRow>
              <ListingProps>
                <span>{props.bedrooms || 0} bed</span>
                <span>{props.bathrooms || 0} bath</span>
                <span>{props.living_area.toLocaleString() || 0} sqft</span>
                <span>{props.property_type ?? 'N/A'}</span>
                <span className='last'>Active</span>
              </ListingProps>
            </Col>
          </Row>
        </Container>
      </Wrapper>
      <ListingInfo>
        <Container>
          <Row>
            <ListingInfoCol>
              <ListingInfoTitle>subdivision</ListingInfoTitle>
              N/A
            </ListingInfoCol>
            <ListingInfoCol>
              <ListingInfoTitle>days on market</ListingInfoTitle>
              N/A
            </ListingInfoCol>
            <ListingInfoCol className='no-border'>
              <ListingInfoTitle>original list price</ListingInfoTitle>
              N/A
            </ListingInfoCol>
          </Row>
        </Container>
      </ListingInfo>
    </>
  );
}
