import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useStorage, getStorageKey } from "./useStorage";


export type ContextType = {
  userToken: string;
  login: (token: string) => Promise<void>;
  logout: () => Promise<void>;
  getToken: () => Promise<string|null>;
};


const AuthContext = createContext<ContextType | null>(null);


export const AuthProvider = ({ children }: any,) => {
  const [userToken, setUserToken] = useStorage("token", null);
  const [loading, setLoading] = useState<boolean>(true)
  
  // call this function when you want to authenticate the user
  const login = async (data: any) => {
    await setUserToken(data);
  };

  // call this function to sign out logged in user
  const logout = async () => {
    await setUserToken(null);
  };

  const getToken = async (): Promise<string|null> => {
    const token = await getStorageKey("token")
    await setUserToken(token);
    return token
  }

  useEffect(() => {
    getToken().then(() => setLoading(false))
  }, [])

  const value = useMemo(
    () => ({
      loading,
      userToken,
      login,
      logout,
      getToken
    }),
    [userToken]
  );
  if (loading) {
    return null;
  }
  
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};