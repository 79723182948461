import React, { useEffect } from "react";
import { useState } from "react";
import { useApi } from "../../../api/useApi";
import {
  Button,
  Form,
  Col,
  Row,
  DropdownButton,
  Dropdown,
  Image,
} from "react-bootstrap";
import { User } from "../../../api/interfaces/user";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import styled from "styled-components";
import { InfoIcon } from "../../components/InfoIcon";

const DropdownIcon = styled(Image)`
  width: 25px;
  margin-right: 5px;
  border-radius: 8px;
`;

const Select = styled(DropdownButton)`
  button {
    text-align: left;
    border: 1px solid var(--main-border-color);
    background-color: #1a233d;
    color: var(--content-color);
  }
`;

const portals: Record<string, any> = {
  REDFIN: {
    name: "Redfin",
    icon: "/images/dropdown_icons/redfin_150.jpeg",
  },
  REALTOR: {
    name: "Realtor",
    icon: "/images/dropdown_icons/realtor_150.png",
  },
  ZILLOW: {
    name: "ZIllow",
    icon: "/images/dropdown_icons/zillow_150.jpg",
  },
};

export const Profile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [profileUpdated, setProfileUpdated] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const context = useApi();
  useEffect(() => {
    const fetchData = async () => {
      const data = await context?.getUserData();
      if (data) {
        setUser(data);
      }
    };

    fetchData().catch(console.error);
  }, []);

  const handleUserUpdate = async (e: any) => {
    e.preventDefault();
    setProfileUpdated(false);
    setIsLoading(true);
    if (user !== null) {
      try {
        await context?.saveUserData(user);
      } catch (error) {
        alert("Error saving user details");
      }
    }
    setIsLoading(false);
    setProfileUpdated(true);
  };

  if (user === null) {
    return <LoadingIndicator />;
  }

  let portalDropDownTitle: React.ReactNode;
  if (user.preferred_portal && portals[user.preferred_portal]) {
    portalDropDownTitle = (
      <>
        <DropdownIcon src={portals[user.preferred_portal].icon} />
        {portals[user.preferred_portal].name}
      </>
    );
  } else {
    portalDropDownTitle = (
      <>
        <DropdownIcon src={portals["REALTOR"].icon} />
        {portals["REALTOR"].name}
      </>
    );
  }

  return (
    <Form onSubmit={handleUserUpdate}>
      <h2>User settings</h2>
      <Form.Group className='mb-3' controlId='formBasicEmail'>
        <Form.Label>Email</Form.Label>
        <Form.Control
          onChange={(e) =>
            setUser({
              ...user,
              email: e.target.value,
            })
          }
          type='email'
          value={user.email}
          placeholder='Enter email'
        />
      </Form.Group>
      <Form.Group className='mb-3' controlId='formBasicPassword'>
        <Form.Label>Password</Form.Label>
        <Form.Control
          onChange={(e) =>
            setUser({
              ...user,
              password: e.target.value,
            })
          }
          type='password'
          placeholder='Password'
        />
      </Form.Group>
      <Row>
        <Col>
          <Form.Group className='mb-3'>
            <Form.Label>
              Preferred Listings Site{" "}
              <InfoIcon placement="bottom" label='Open listings in your preferred real estate portal, if available.' />
            </Form.Label>
            <Select title={portalDropDownTitle}>
              {Object.keys(portals).map((k) => (
                <Dropdown.Item
                  key={k}
                  onClick={() =>
                    setUser({
                      ...user,
                      preferred_portal: k,
                    })
                  }
                >
                  <DropdownIcon src={portals[k].icon} />
                  {portals[k].name}
                </Dropdown.Item>
              ))}
            </Select>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Label>
            Financing Preferences{" "}
            <InfoIcon placement="bottom" label='Will be applied to any payment and pro forma estimations' />
          </Form.Label>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className='mb-3'>
            <Form.Label>Interest Rate</Form.Label>
            <Form.Control
              onChange={(e) =>
                setUser({
                  ...user,
                  financial_interest_rate: parseFloat(e.target.value),
                })
              }
              width={80}
              placeholder='7'
              value={user?.financial_interest_rate}
              type='number'
              step='0.01'
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className='mb-3'>
            <Form.Label>Term (Years)</Form.Label>
            <Form.Control
              onChange={(e) =>
                setUser({
                  ...user,
                  financial_term: parseFloat(e.target.value),
                })
              }
              width={80}
              placeholder='30'
              value={user?.financial_term}
              type='number'
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className='mb-3'>
            <Form.Label>Down Pmt. %</Form.Label>
            <Form.Control
              onChange={(e) =>
                setUser({
                  ...user,
                  financial_downpayment: parseFloat(e.target.value),
                })
              }
              width={80}
              placeholder='35'
              value={user?.financial_downpayment}
              type='number'
              step='0.01'
            />
          </Form.Group>
        </Col>
      </Row>
      <div style={{ textAlign: "right" }}>
        <Button variant='primary' type='submit' disabled={isLoading}>
          {isLoading ? (
            <>
              <LoadingIndicator />
              &nbsp; Saving...
            </>
          ) : (
            "Save"
          )}
        </Button>
      </div>
      {profileUpdated && (
        <div className='mt-3' style={{ color: "#0CCCF7" }}>
          Information updated
        </div>
      )}
    </Form>
  );
};
