import { useEffect, useState } from "react";
import { Module } from "./module.type";
import { useApi } from "../../../api/useApi";

export function useModules(): [Module[], boolean] {
  const context = useApi();
  const defaultModules: Module[] = [
    {
      added: true,
      moduleId: "rentomatic",
      title: "RENT-O-MATIC",
      description:
        "Rent estimate on every active listing, showing you the comparables used to derive the estimate.",
      price: "Added",
      imagePath: "/images/module_icons/rent-o-matic.png",
      url: "/rentomatic",
    },
    {
      added: false,
      moduleId: "proformator",
      title: "THE PROFORMANATOR",
      description:
        "Profit & loss scenarios on every listing with one click. Fields auto-filled from listing data, local customs, and your financial preferences.",
      price: "$9.95/mo (included in PLUS)",
      imagePath: "/images/module_icons/proformanator-logo.png",
      url: "/proforma",
    },
    {
      added: false,
      moduleId: "cream",
      title: "C.R.E.A.M",
      description: "Rapidly scan for profitable properties by searching our opinion of rent and return on all available listings.",
      price: "$34.95/mo (included in PLUS)",
      imagePath: "/images/module_icons/cream_logo.png",
      url: "/cream",
    },
    {
      added: false,
      moduleId: "dossier",
      title: "DOSSIER",
      description: "Deep dive any listing, get owner information, non-public documents, complete listing history, and more.",
      price: "$19.95 per listing (included in PLUS, 3 per month)",
      imagePath: "/images/module_icons/dossier.png",
      url: "/dossier",
    },
    {
      added: true,
      moduleId: "hq",
      title: "HQ",
      description: "Investigate any property, not just listings. We provide rent and sales price estimations, comparables, and owner contact information.",
      price: "PRICE",
      imagePath: "/images/module_icons/hq.png",
      url: "/hq",
    },
  ];

  const [modules, setModules] = useState(defaultModules);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let intervalId: NodeJS.Timer
    let totalTimes: number
    const fetchData = async () => {
      try {
        const data = await context?.getUserData();
        totalTimes += 1
        if (data?.has_subscription) {
          setModules([
            modules[0],
            {
              ...modules[1],
              added: true,
            },
            {
              ...modules[2],
              added: true,
            },
            {
              ...modules[3],
              added: true,
            },
            {
              ...modules[4],
              added: true,
            },
          ]);
        }
        if (totalTimes >= 15 || data?.has_subscription) {
          clearInterval(intervalId)
        }
      } finally {
        setLoading(false);
      }
    };

    intervalId = setInterval(() => {
      fetchData().catch(console.error);
    }, 3000)

  }, []);
  return [modules, loading];
}
