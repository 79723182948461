import * as Sentry from "@sentry/browser";
import { Form } from "react-bootstrap";
import { LoadingButton } from "./LoadingButton";
import styled from "styled-components";
import { X } from "react-feather";
import { useState } from "react";

interface Props {
  onClose: () => void;
}

const Feedback = styled.div`
  background: #111729;
  border-radius: 8px;
  width: 400px;
  right: 0;
  margin-top: 40px;
  position: absolute;
  padding: 20px;
  z-index: 1000;
  top: 50vh;
  color: #d4d4f9;
  border: 1px solid rgb(212, 212, 249);

  textarea {
    border: 1px solid #6a76a0;
    background: #1a233d !important;
    color: #fff !important;
  }

  .btn {
    width: auto;
    float: right;
  }
`;

const CloseBtn = styled.button`
  border: none;
  background: none;
  position: absolute;
  color: rgb(212, 212, 249);
  right: 10px;
  top: 16px;
`;

const getCurrentUrl = async (): Promise<string> =>
  new Promise((resolve, reject) => {
    const channel = new MessageChannel();
    channel.port1.onmessage = ({ data }) => {
      channel.port1.close();
      resolve(data);
    };
    window.parent.postMessage({ type: "requestUrl" }, "*", [channel.port2]);
  });

export function FeedbackForm(props: Props) {
  const [message, setMessage] = useState<string | null>("");
  const sendFeedback = async () => {
    const currentUrl = await getCurrentUrl();
    if (message !== "") {
      Sentry.sendFeedback(
        {
          message: `
            ${message}

            at ${currentUrl}
          `,
        },
        {
          includeReplay: true,
        }
      );
    }
    setMessage(null);
  };

  return (
    <Feedback id="feedback">
      <Form>
        <h2 className='mb-3'>SEND FEEDBACK</h2>
        <CloseBtn onClick={props.onClose}>
          <X />
        </CloseBtn>
        {message === null && <>Thank you! Your message has been sent!</>}
        {message !== null && (
          <>
            <Form.Group className='mb-3'>
              <Form.Control
                placeholder='Type your message here....'
                as='textarea'
                onChange={(e) => setMessage(e.target.value)}
                rows={5}
              />
            </Form.Group>
            <LoadingButton label='Send Message' callback={sendFeedback} />
          </>
        )}
      </Form>
    </Feedback>
  );
}
