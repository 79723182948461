import React, { useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";

import { Proforma as ProformaType } from "../../../api/interfaces/proforma";
import styled from "styled-components";
import { formatEquity, formatNumericValue } from "./components/PriceInput";
import {
  NetRentalIncome,
  ProformaTable,
  NetOperatingIncome,
  FinancingCosts,
  AssumptionsEditor,
  TransactionCosts,
} from "./components/";

import { ModulePreview } from "../../widgets";
import { useAssumptions, useProforma } from "./hooks";
import { ListingDetails } from "../../components/listing";
import { Listing } from "../../../api/interfaces/listing";
import { Save, Zap } from "react-feather";
import { Sidebar } from "../../components/layout";
import { useListing } from "../../hooks/useListing";
import { USDollar } from "../../utils";
import { SubjectListing } from "../../components";

const RentalIncome = styled.div`
  background: #f8f9fb;
  padding: 10px calc(var(--bs-gutter-x) * 0.5) 0;
  margin-bottom: 10px;
`;

const Address = styled.div`
  color: #333;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 130%;
`;

const ProformaBtn = styled.button`
  background: none;
  border: none;
  color: #d4d4f9;
  font-weight: 600;

  &:disabled {
    opacity: 0.5;
  }
`;

const getPImonthly = (
  loanAmount: number,
  interestRate: number,
  termInYears: number
) => {
  interestRate = interestRate === 0 ? 0 : interestRate / 100;
  let monthlyInterestRate = interestRate === 0 ? 0 : interestRate / 12;
  let numberOfMonthlyPayments = termInYears * 12;
  return Math.round(
    (monthlyInterestRate *
      loanAmount *
      Math.pow(1 + monthlyInterestRate, numberOfMonthlyPayments)) /
    (Math.pow(1 + monthlyInterestRate, numberOfMonthlyPayments) - 1)
  );
};

interface Props {
  onSave: (proforma: ProformaType) => Promise<void>;
  onDelete: () => Promise<void>;
  onReset: () => void;
  proforma: ProformaType;
  listing: Listing | null;
}

const Footer = () => {
  return (
    <div className='footer alignLeft'>
      The Proformanator analysis is for illustrative purposes only. Use at your
      own risk. SELF Realty, LLC makes no warranty as to the accuracy or
      reasonableness of any value or assumption. User to verify all information
      independently.
    </div>
  );
};

export const Proforma = (props: Props) => {
  const [piMonthly, setPIMonthly] = useState(0);
  const [rentIncome, setRentIncome] = useState<number>(0);
  const [netOperatingIncome, setNetOperatingIncome] = useState<number>(0);

  const assumptionsState = useAssumptions(props.proforma);
  const proformaState = useProforma(props.proforma);

  const saveProforma = () => {
    const proforma: ProformaType = {
      proforma_id: props.proforma.proforma_id,
      closing_costs: proformaState.closingCosts,
      closing_date: proformaState.closingDate,
      closing_costs_rate: assumptionsState.closingCostsRate,
      clean_and_repair: assumptionsState.cleanAndRepair,
      offer_price: proformaState.offerPrice,
      down_payment: proformaState.downPayment,
      down_payment_rate: assumptionsState.downPaymentRate,
      interest_rate: assumptionsState.interestRate,
      loan_term: assumptionsState.loanTerm,
      rent_estimate: proformaState.rentEstimate,
      hoa_transfer: proformaState.hoaTransfer,

      vacancy: assumptionsState.vacancy,
      enable_tax_user_estimate: assumptionsState.enableTaxUserEstimate,
      user_tax_rate: assumptionsState.userTaxRate,
      property_tax: proformaState.propertyTax,
      provider_estimated_taxes: proformaState.providerEstimatedTaxes,

      hoa_fee: proformaState.hoaFee,
      repair_and_maintenance: proformaState.repairAndMaintenance,
      repair_and_maintenance_rate: assumptionsState.repairAndMaintenanceRate,
      property_management: proformaState.propertyManagement,
      property_management_rate: assumptionsState.propertyManagementRate,
      listing_fees: proformaState.listingFees,
      lease_listing_fee: assumptionsState.leaseListingFee,
      insurance: proformaState.insurance,
      insurance_rate: assumptionsState.insuranceRate,
      other: proformaState.other,
    };

    props.onSave(proforma);
  };

  useEffect(() => {
    assumptionsState.setClosingCostsRate(
      proformaState.closingCosts / (proformaState.offerPrice / 100)
    );
  }, [proformaState.closingCosts, proformaState.offerPrice]);

  useEffect(() => {
    assumptionsState.setDownPaymentRate(
      proformaState.downPayment / (proformaState.offerPrice / 100)
    );
  }, [proformaState.downPayment, proformaState.offerPrice]);

  // Repair and Maintenance  update
  useEffect(() => {
    if (proformaState.repairAndMaintenance !== null) {
      assumptionsState.setRepairAndMaintenanceRate(
        proformaState.repairAndMaintenance / (proformaState.rentEstimate / 100)
      );
    }
  }, [proformaState.rentEstimate, proformaState.repairAndMaintenance]);

  // Property Management update
  useEffect(() => {
    if (proformaState.propertyManagement !== null) {
      assumptionsState.setPropertyManagementRate(
        proformaState.propertyManagement / (proformaState.rentEstimate / 100)
      );
    }
  }, [proformaState.rentEstimate, proformaState.propertyManagement]);

  useEffect(() => {
    if (proformaState.listingFees !== null) {
      assumptionsState.setLeaseListingFee(
        ((proformaState.listingFees * 12) / proformaState.rentEstimate) * 100
      );
    }
  }, [proformaState.listingFees, proformaState.rentEstimate]);

  useEffect(() => {
    if (proformaState.insurance !== null) {
      assumptionsState.setInsuranceRate(
        ((proformaState.insurance * 12) / proformaState.offerPrice) * 100
      );
    }
  }, [proformaState.insurance, proformaState.offerPrice]);

  useEffect(() => {
    if (
      assumptionsState.enableTaxUserEstimate &&
      proformaState.propertyTax !== null
    ) {
      assumptionsState.setUserTaxRate(
        ((proformaState.propertyTax * 12) / proformaState.offerPrice) * 100
      );
    }
  }, [proformaState.propertyTax, proformaState.offerPrice]);

  useEffect(() => {
    setPIMonthly(
      getPImonthly(
        proformaState.offerPrice - proformaState.downPayment,
        assumptionsState.interestRate,
        assumptionsState.loanTerm
      )
    );
  }, [
    assumptionsState.loanTerm,
    assumptionsState.interestRate,
    proformaState.offerPrice,
    proformaState.downPayment,
  ]);

  const acquisitionCosts = [
    proformaState.closingCosts,
    assumptionsState.cleanAndRepair,
    proformaState.hoaTransfer,
    -proformaState.propertyTaxProration,
  ].reduce((partialSum, a) => partialSum + a, 0);

  const yearReturn = parseFloat(
    (
      (((netOperatingIncome - piMonthly) * 12) /
        (acquisitionCosts + proformaState.downPayment)) *
      100
    ).toFixed(2)
  );

  return (
    <>
      <Row>
        <Col>
          <SubjectListing />
          <div className='expandedView mainCol'>
            {props.listing && (
              <Address className='mb-3 mt-2'>
                {/* {props.listing.unparsed_address} */}
                Hover over line items for tool tips
              </Address>
            )}
            <Container>
              <Row>
                <Col>
                  <TransactionCosts
                    closingDate={proformaState.closingDate}
                    setClosingDate={proformaState.setClosingDate}
                    offerPrice={proformaState.offerPrice}
                    downPayment={proformaState.downPayment}
                    propertyTax={proformaState.propertyTax ?? 0}
                    closingCosts={proformaState.closingCosts}
                    hoaTransfer={proformaState.hoaTransfer}
                    acquisitionCosts={acquisitionCosts}
                    cleanAndRepair={assumptionsState.cleanAndRepair}
                    closingCostsRate={assumptionsState.closingCostsRate}
                    setProRataTax={proformaState.setPropertyTaxProration}
                    setHoaTransfer={proformaState.setHoaTransfer}
                    setOfferPrice={proformaState.setOfferPrice}
                    setClosingCosts={proformaState.setClosingCosts}
                  />
                </Col>
                <Col>
                  <FinancingCosts
                    piMonthly={piMonthly}
                    loanTerm={assumptionsState.loanTerm}
                    downPaymentRate={assumptionsState.downPaymentRate}
                    setDownPayment={proformaState.setDownPayment}
                    offerPrice={proformaState.offerPrice}
                    downPayment={proformaState.downPayment}
                  />
                </Col>
              </Row>
            </Container>
            <Row>
              <Col>
                <RentalIncome>
                  <NetRentalIncome
                    rentEstimate={proformaState.rentEstimate}
                    vacancy={assumptionsState.vacancy}
                    onChange={setRentIncome}
                    onRentChange={proformaState.setRentEstimate}
                  />
                </RentalIncome>
              </Col>
            </Row>
            <Container>
              <Row>
                <Col>
                  <NetOperatingIncome
                    repairAndMaintenance={proformaState.repairAndMaintenance}
                    propertyManagement={proformaState.propertyManagement}
                    listingFees={proformaState.listingFees}
                    insurance={proformaState.insurance}
                    other={proformaState.other}
                    propertyTax={proformaState.propertyTax}
                    hoaFee={proformaState.hoaFee}
                    setRepairAndMaintenance={
                      proformaState.setRepairAndMaintenance
                    }
                    offerPrice={proformaState.offerPrice}
                    insuranceRate={assumptionsState.insuranceRate}
                    leaseListingFee={assumptionsState.leaseListingFee}
                    repairAndMaintenanceRate={
                      assumptionsState.repairAndMaintenanceRate
                    }
                    propertyManagementRate={
                      assumptionsState.propertyManagementRate
                    }
                    userTaxRate={assumptionsState.userTaxRate}
                    enableTaxUserEstimate={
                      assumptionsState.enableTaxUserEstimate
                    }
                    providerEstimatedTaxes={
                      proformaState.providerEstimatedTaxes
                    }
                    providerAssosiationFee={
                      proformaState.providerAssosiationFee
                    }
                    setPropertyManagement={proformaState.setPropertyManagement}
                    setListingFees={proformaState.setListingFees}
                    setInsurance={proformaState.setInsurance}
                    setOther={proformaState.setOther}
                    setPropertyTax={proformaState.setPropertyTax}
                    setHoaFee={proformaState.setHoaFee}
                    rentEstimate={proformaState.rentEstimate}
                    netIncome={rentIncome}
                    onChange={setNetOperatingIncome}
                  />
                </Col>
              </Row>
            </Container>
            <Container>
              <Row>
                <Col>
                  <ProformaTable>
                    <thead>
                      <tr>
                        <th className='w60'>Mortgage Payment</th>
                        <th className='w20'>
                          <span className='textOnly'>
                            {USDollar.format(piMonthly)}
                          </span>
                        </th>
                        <th className='w20'>
                          <span className='textOnly'>
                            {USDollar.format(piMonthly * 12)}
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tfoot>
                      <tr>
                        <td className='w60'>Net Income</td>
                        <td align='right' className='w20'>
                          <div className='textOnly'>
                            {formatNumericValue(netOperatingIncome - piMonthly)}
                          </div>
                        </td>

                        <td align='right' className='w20'>
                          <div className='textOnly'>
                            {formatNumericValue(
                              (netOperatingIncome - piMonthly) * 12
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className='w60'>Return on Equity - Year 1</td>
                        <td align='right' className='w20'></td>
                        <td align='right' className='w20'>
                          <div className='textOnly'>
                            {formatEquity(yearReturn)}
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  </ProformaTable>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
        <Sidebar>
          {/* <ModulePreview
            name='THE PROFORMANATOR'
            imageName='proformanator-logo.png'
            className='mb-3'
          /> */}
          {/* {props.listing && (
            <div className='mb-3'>
              <ListingDetails listing={props.listing} />
            </div>
          )} */}
          <div className='mb-3'>
            <ProformaBtn onClick={saveProforma}>
              <Save /> Save
            </ProformaBtn>
            <ProformaBtn
              onClick={
                props.proforma.proforma_id === null
                  ? props.onReset
                  : props.onDelete
              }
            >
              <Zap /> Reset
            </ProformaBtn>
          </div>
          <AssumptionsEditor
            downPaymentRate={assumptionsState.downPaymentRate}
            setProformaDownPayment={proformaState.setDownPayment}
            closingCostsRate={assumptionsState.closingCostsRate}
            setProformaClosingsCosts={proformaState.setClosingCosts}
            repairAndMaintenanceRate={assumptionsState.repairAndMaintenanceRate}
            setProformaRepairAndMaintenance={
              proformaState.setRepairAndMaintenance
            }
            vacancy={assumptionsState.vacancy}
            setVacancy={assumptionsState.setVacancy}
            cleanAndRepair={assumptionsState.cleanAndRepair}
            setCleanAndRepair={assumptionsState.setCleanAndRepair}
            loanTerm={assumptionsState.loanTerm}
            setLoanTerm={assumptionsState.setLoanTerm}
            interestRate={assumptionsState.interestRate}
            setInterestRate={assumptionsState.setInterestRate}
            insurance={assumptionsState.insuranceRate}
            setInsurance={proformaState.setInsurance}
            propertyManagementRate={assumptionsState.propertyManagementRate}
            setPropertyManagement={proformaState.setPropertyManagement}
            leaseListingFee={assumptionsState.leaseListingFee}
            setListingFees={proformaState.setListingFees}
            rentEstimate={proformaState.rentEstimate}
            offerPrice={proformaState.offerPrice}
            closingCosts={proformaState.closingCosts}
            downPayment={proformaState.downPayment}
            userTaxRate={assumptionsState.userTaxRate}
            setTaxUserEstimate={proformaState.setPropertyTax}
            providerEstimatedTaxes={proformaState.providerEstimatedTaxes}
            enableTaxUserEstimate={assumptionsState.enableTaxUserEstimate}
            setEnableTaxUserEstimate={assumptionsState.setEnableTaxUserEstimate}
          />
          <Footer />
        </Sidebar>
      </Row>
    </>
  );
};
