import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row, Container } from "react-bootstrap";
import { Check, X } from "react-feather";
import { useApi } from "../../../api/useApi";
import { LoadingButton } from "../../components";
import styled from "styled-components";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { Header } from "../../widgets";

const SubscriptionFeatures = styled.ul`
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    line-height: 25px;

    svg {
      color: #3eaf3f;
      height: 15px;
      width: 15px;
      margin-right: 3px;
    }

    &.disabled {
      color: #666d86;
    }

    &.disabled svg {
      color: #ff6058;
    }
  }
`;

const Promo = styled.p`
  text-align: center;
  color: #fff;
`

export const PlanManagement = () => {
  const [activePlan, setActivePlan] = useState(1);
  const [currentPlan, setCurrentPlan] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const context = useApi();
  useEffect(() => {
    let intervalId: NodeJS.Timer
    let totalTimes: number
    const fetchData = async () => {
      try {
        const data = await context?.getUserData();
        setCurrentPlan(data?.has_subscription ? 2 : 1);
        setActivePlan(data?.has_subscription ? 2 : 1);
        totalTimes += 1
        if (totalTimes >= 15 || data?.has_subscription) {
          clearInterval(intervalId)
        } 
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    intervalId = setInterval(() => {
      fetchData().catch(console.error);
    }, 3000)
  }, []);

  const handleRedirectToBillingPortal = async () => {
    try {
      const response = await context?.manageSubscription();
      window.open(response.url, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Header darkTheme={true} />
      <h2>Select Plan</h2>
      <Row className='mb-3 mt-4'>
        <Col>
          <div
            className={"subscriptionPlan " + (activePlan === 1 && "active")}
            onClick={() => setActivePlan(1)}
          >
            <div className={"subscriptionPlanName "}>Free</div>
            USD $0/month
          </div>
        </Col>
        <Col>
          <div
            className={"subscriptionPlan " + (activePlan === 2 && "active")}
            onClick={() => setActivePlan(2)}
          >
            <div className='subscriptionPlanName'>PLUS</div>
            USD $39/month
          </div>
        </Col>
      </Row>

      {activePlan === 1 && <Promo>Get our opinion of market rent on every listing.</Promo>}
      {activePlan === 2 && <Promo>Includes all SELF Realty services</Promo>}

      <h4>PLAN DETAILS</h4>

      <div className='mb-4'>
        <SubscriptionFeatures>
          <li>
            <Check />
            RENT-O-MATIC
          </li>

          {activePlan === 1 && (
            <li className='disabled'>
              <X />
              THE PROFORMANATOR
            </li>
          )}
          {activePlan === 1 && (
            <li className='disabled'>
              <X />
              C.R.E.A.M. search
            </li>
          )}
          {activePlan === 1 && (
            <li className='disabled'>
              <X />
              DOSSIER (3 credits/mo included)
            </li>
          )}
          {activePlan === 1 && (
            <li className='disabled'>
              <X />
              First look at new services
            </li>
          )}

          {activePlan === 2 && (
            <li>
              <Check />
              THE PROFORMANATOR
            </li>
          )}
          {activePlan === 2 && (
            <li>
              <Check />
              C.R.E.A.M. search
            </li>
          )}
          {activePlan === 2 && (
            <li>
              <Check />
              DOSSIER (3 credits/mo included)
            </li>
          )}
          {activePlan === 2 && (
            <li>
              <Check />
              First look at new services
            </li>
          )}
        </SubscriptionFeatures>
      </div>

      {activePlan === currentPlan && (
        <Button disabled className="upgradePlanBtn">
          Current plan
        </Button>
      )}
      {activePlan > currentPlan && (
        <Button onClick={() => navigate("/dashboard/subscribe")}>
          <b>Upgrade to PLUS</b>
        </Button>
      )}
      {activePlan < currentPlan && (
        <LoadingButton
          className="downgradePlanBtn"
          label={<b>Downgrade Plan</b>}
          callback={handleRedirectToBillingPortal}
        />
      )}
    </>
  );
};
