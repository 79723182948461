import { PriceInput } from "./PriceInput";
import { ProformaTable } from "./ProformaTable";
import { InfoTooltip } from "../../../components/InfoTooltip";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { USDollar } from "../../../utils";

interface Props {
  offerPrice: number;
  propertyTax: number;
  downPayment: number;
  closingCosts: number;
  hoaTransfer: number;
  acquisitionCosts: number;

  cleanAndRepair: number;
  closingCostsRate: number;
  closingDate: string;

  setClosingCosts: (val: number) => void;
  setClosingDate: (val: string) => void;
  setOfferPrice: (val: number) => void;
  setProRataTax: (val: number) => void;
  setHoaTransfer: (val: number) => void;
}

export const TransactionCosts = (props: Props) => {
  const nextYearDate = new Date(new Date().getFullYear() + 1, 0, 1);
  const [currentTaxProRata, setCurrentTax] = useState<number>(
    props.propertyTax
  );
  const { acquisitionCosts } = props;

  useEffect(() => {
    const diffTime = Math.abs(
      nextYearDate.getTime() - new Date(props.closingDate).getTime()
    );
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const taxProRata = props.propertyTax * 12 * (diffDays / 365);
    setCurrentTax(props.propertyTax * 12 - taxProRata);
    props.setProRataTax(props.propertyTax * 12 - taxProRata);
  }, [props.closingDate, props.propertyTax]);

  return (
    <ProformaTable>
      <thead>
        <tr>
          <th colSpan={2}>Transaction Information</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <InfoTooltip label='Enter your offer amount. Default is list price.'>
              Offer Price
            </InfoTooltip>
          </td>
          <td align='right'>
            <PriceInput
              value={props.offerPrice}
              onUpdate={props.setOfferPrice}
            />
          </td>
        </tr>
        <tr>
          <td>
            <InfoTooltip label='30 days is default'>Closing Date</InfoTooltip>
          </td>
          <td align='right'>
            <Form.Control
              style={{ width: 90, fontSize: 12 }}
              type='date'
              value={props.closingDate}
              onChange={(e) => props.setClosingDate(e.target.value)}
            />
          </td>
        </tr>
        <tr>
          <td>
            <InfoTooltip label='"Closing Costs" customarily includes escrow fees, title insurance, recording fees.'>
              Closing Costs
            </InfoTooltip>
          </td>
          <td align='right'>
            <PriceInput
              value={props.closingCosts}
              onUpdate={props.setClosingCosts}
            />
          </td>
        </tr>
        <tr>
          <td>
            <InfoTooltip label='Cleaning, touch-up, handyman, make-ready, any costs you expect to be necessary to prepare the home for the next occupant.'>
              Clean & Repair
            </InfoTooltip>
          </td>
          <td align='right'>
            <div className='textOnly'>
              {USDollar.format(props.cleanAndRepair)}
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <InfoTooltip label="Seller typically credits Buyer at closing for Seller's portion of annual property taxes (prorated)">
              Prop. Tax Proration
            </InfoTooltip>
          </td>
          <td align='right'>
            <div className='textOnly'>{USDollar.format(currentTaxProRata)}</div>
          </td>
        </tr>
        <tr>
          <td>
            <InfoTooltip label='Used in condominium transactions or if the property is part of a Home Owners Association (HOA). The HOA charges a fee to manage the transfer of ownership. New owners may also be required to contribute to the working capital of the association.  In Texas, the HOA details are delivered to the Buyer in the form of a "resale certificate." We use 2x the HOA Fee as a starting point assumption.'>
              HOA Transfer
            </InfoTooltip>
          </td>
          <td align='right'>
            <PriceInput
              value={props.hoaTransfer}
              onUpdate={props.setHoaTransfer}
            />
          </td>
        </tr>
        <tr>
          <td>
            <InfoTooltip label="Seller typically credits Buyer at closing for Seller's portion of annual property taxes (prorated). The amount displayed is credited to the Buyer, effectively reducing the amount of cash the Buyer is required to bring to closing. The Buyer's property tax obligations are reintroduced in the Operating Costs below.">
              Cash To Close
            </InfoTooltip>
          </td>
          <td align='right'>
            <div className='textOnly'>
              {USDollar.format(acquisitionCosts + props.downPayment)}
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <InfoTooltip label='Cash to Close + Loan Amount'>
              Total Acquisition Cost
            </InfoTooltip>
          </td>
          <td align='right'>
            <div className='textOnly'>
            {USDollar.format((acquisitionCosts + props.downPayment) + Math.max(props.offerPrice - props.downPayment, 0))}
            </div>
          </td>
        </tr>
      </tbody>
    </ProformaTable>
  );
};
