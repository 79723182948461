import Pagination from "react-bootstrap/esm/Pagination";

interface Props {
  total: number;
  offset: number;
  limit: number;
}

const getUrl = (i: number, limit: number) => {
  const searchParams = new URLSearchParams();
  searchParams.set("offset", String(i * limit));
  return "/#/cream/?" + searchParams.toString();
};

function PaginationContainer({ total, offset, limit }: Props) {
  const current = offset / limit;
  const totalPages = Math.ceil(total / limit);

  const start = Math.max(current - 4, 0);
  const end = Math.min(current + 4, totalPages - 1);

  const range = Array(end - start + 1)
    .fill(0)
    .map((_, idx) => start + idx);

  const items = range.map((i) => {
    const url = getUrl(i, limit);

    return (
      <Pagination.Item href={url} key={i} active={i === current}>
        {i + 1}
      </Pagination.Item>
    );
  });
  if (total <= limit || totalPages === 0) {
    return null;
  }

  return (
    <Pagination>
      {current > 0 && (
        <Pagination.Prev href={getUrl(current - 1, limit)}>
          &lt; Previous
        </Pagination.Prev>
      )}
      {items}
      {current + 1 < totalPages && (
        <Pagination.Next href={getUrl(current + 1, limit)}>
          Next &gt;
        </Pagination.Next>
      )}
    </Pagination>
  );
}

export default PaginationContainer;
