import { Button } from "react-bootstrap";
import { User } from "react-feather";
import { Link } from "react-router-dom";

export function RegisterConfirmation() {
  return (
    <>
      <div className='icon-wrapper'>
        <User />
      </div>
      <h1 className='mb-3'>Registration confirmed</h1>
      <Link to='/accounts/login'><Button>
        Login
      </Button></Link>
    </>
  );
}
