import * as Sentry from "@sentry/browser";
import React, { useEffect, useRef, useState } from "react";
import { Location, Outlet, useLocation } from "react-router-dom";
import Menu from "./Menu";
import { Container, Image } from "react-bootstrap";
import { css, styled } from "styled-components";
import { FeedbackForm } from "./FeedbackForm";
import { Navigation } from "./layout/Navigation";
import { useAuth } from "../hooks/useAuth";

const View = styled.div<{ $expanded: boolean }>`
  width: 330px;
  background-color: #111729;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 44px 0 22px;
  font-size: 14px;
  color: #d4d4f9;
  position: absolute;
  right: 80px;
  top: 55px;
  overflow: hidden;
  z-index: 200;
  height: calc(97vh - 55px);
  box-shadow: 0px 3.946px 10.522px 0px rgba(0, 0, 0, 0.5);

  &::-webkit-scrollbar {
    display: none;
  }

  ${(props) =>
    props.$expanded &&
    css`
      width: 920px;
    `}
`;

const Sidebar = styled.div`
  z-index: 1000;
  position: fixed;
  right: 75px;
  top: 55px;
  width: 80px;
  background: #000000;
  padding: 11px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0px 3.946px 10.522px 0px rgba(0, 0, 0, 0.5);

  .menuBtn {
    width: 58px;
    height: 58px;
    color: #c8c8ec;
    font-size: 20px;
    background-color: #383d53;
    border: none;
    display: block;

    &:active,
    &.active,
    &:hover {
      background-color: var(--bs-btn-border-color);
      color: #fff;
    }

    img {
      width: 58px;
      height: 58px;
      display: block;
    }
  }
`;

const Logo = styled.div`
  height: 55px;
  z-index: 100;
  position: fixed;
  right: 75px;
  width: 80px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  background: #ffcf6f;
  box-shadow: 0px 3.946px 10.522px 0px rgba(0, 0, 0, 0.5);

  img {
    width: 100%;
    border-top-left-radius: 8px;
    background: #000;
    padding: 10px 7px 5px;
  }
`;

const getCurrentUrl = async (): Promise<string> =>
  new Promise((resolve, reject) => {
    const channel = new MessageChannel();
    channel.port1.onmessage = ({ data }) => {
      channel.port1.close();
      resolve(data);
    };
    window.parent.postMessage({ type: "requestUrl" }, "*", [channel.port2]);
  });

const toggleExpandedView = (expanded: boolean): Promise<void> =>
  new Promise((resolve, reject) => {
    const channel = new MessageChannel();
    channel.port1.onmessage = ({ data }) => {
      channel.port1.close();
      resolve(data);
    };
    window.parent.postMessage({ type: "resize", expanded }, "*", [
      channel.port2,
    ]);
  });

const collapseView = (): Promise<void> =>
  new Promise((resolve, reject) => {
    const channel = new MessageChannel();
    channel.port1.onmessage = ({ data }) => {
      channel.port1.close();
      resolve(data);
    };
    window.parent.postMessage({ type: "resize", collapsed: true }, "*", [
      channel.port2,
    ]);
  });

const destroyIframe = (): Promise<void> =>
  new Promise((resolve, reject) => {
    const channel = new MessageChannel();
    channel.port1.onmessage = ({ data }) => {
      channel.port1.close();
      resolve(data);
    };
    window.parent.postMessage({ type: "destroy" }, "*", [channel.port2]);
  });

const isViewExpanded = (location: Location): boolean => {
  return (
    location.pathname === "/help" ||
    location.pathname === "/accounts/register/terms" ||
    location.pathname.startsWith("/search") ||
    location.pathname.startsWith("/not_found") ||
    location.pathname.startsWith("/dashboard") ||
    location.pathname.startsWith("/cream") ||
    location.pathname.startsWith("/dossier") ||
    location.pathname.startsWith("/proforma") ||
    location.pathname.startsWith("/hq") ||
    location.pathname.startsWith("/rentomatic")
  );
};

export function LayoutView() {
  const documentRef = useRef(document);
  const auth = useAuth();
  const location = useLocation();
  const [expanded, setExpanded] = useState<boolean>(true);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [currentUrl, setCurrentUrl] = useState<null | string>(null);

  const [creamUsageAccepted, setCreamUsageAccepted] = useState<boolean>(false);

  function logCustomError(err: string) {
    const captureContext = {
      tags: {
        url: currentUrl,
      },
    };
    Sentry.captureMessage(`${err} for ${currentUrl}`, captureContext);
  }

  useEffect(() => {
    getCurrentUrl().then(setCurrentUrl);
  }, []);

  useEffect(() => {
    const isExpanded = isViewExpanded(location);
    if (collapsed) {
      setCollapsed(false);
    }
    setExpanded(isExpanded);
    toggleExpandedView(isExpanded);
  }, [location]);

  useEffect(() => {
    const isExpanded = isViewExpanded(location);
    if (collapsed) {
      collapseView();
    } else {
      setExpanded(isExpanded);
    }
  }, [collapsed, showFeedbackForm]);

  useEffect(() => {
    toggleExpandedView(expanded);
  }, [expanded]);

  useEffect(() => {
    if (showFeedbackForm) {
      toggleExpandedView(expanded);
    }
  }, [showFeedbackForm]);

  useEffect(() => {
    documentRef.current.addEventListener("click", (event: MouseEvent) => {
      const view = documentRef.current.getElementById("view");
      const logo = documentRef.current.getElementById("logo");
      const sidebar = documentRef.current.getElementById("sidebar");
      const feedback = documentRef.current.getElementById("feedback");
      const isClickedInsideFrame = [
        // @ts-ignore
        view?.contains(event.target),
        // @ts-ignore
        logo?.contains(event.target),
        // @ts-ignore
        sidebar?.contains(event.target),
        // @ts-ignore
        feedback?.contains(event.target),
      ].some((v) => v === true);
      // @ts-ignore
      if (!isClickedInsideFrame && event.target?.tagName === "HTML") {
        destroyIframe();
      }
    });
  }, []);

  return (
    <>
      <View
        id='view'
        $expanded={expanded}
        className={!expanded ? "sidebarStyle" : ""}
      >
        <Container>
          {auth?.userToken && <Navigation />}
          <Outlet
            context={{
              currentUrl,
              logCustomError,
              creamUsageAccepted,
              setCreamUsageAccepted,
            }}
          />
        </Container>
      </View>
      <Logo id='logo'>
        <Image src='/images/self-logo-white-transparent.png' />
      </Logo>
      <Sidebar id='sidebar'>
        <Menu setShowFeedbackForm={setShowFeedbackForm} />
      </Sidebar>
      {showFeedbackForm && (
        <FeedbackForm onClose={() => setShowFeedbackForm(false)} />
      )}
    </>
  );
}
