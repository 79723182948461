import styled from "styled-components";
import Form from "react-bootstrap/Form";

const Title = styled.div`
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26.699px;
  padding: 32px;
`;

const Content = styled.div`
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26.699px;
  padding: 0px 80px;
`;

const Footer = styled.div`
  color: #000;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 26.699px;
  padding: 20px 0 10px;
`;

const Checkbox = styled.div`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26.699px;
  padding: 20px 0 0 250px;

  input.form-check-input {
    border: 1px solid #000;
    border-radius: 0
  }
`;

interface Props {
  onAccept: () => void
}

export const CreamUsage = (props: Props) => {
  return (
    <>
      <Title>
        Find profitable properties by searching our opinion of rent and return
        on all available listings.
      </Title>
      <Content>
        This is not investment advice. Opinions of potential rent and return are
        based on information available to us at the time of use. Actual rents
        and returns could be significantly different than the opinions
        displayed. No warranty expressed or implied. Use at your own risk.
      </Content>
      <Checkbox>
        <Form.Check type='checkbox' label='I agree' onChange={() => props.onAccept()} />
      </Checkbox>
      <Footer>Copyright 2023-2024 SELF Realty, LLC</Footer>
    </>
  );
};
