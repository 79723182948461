import React, { useEffect, useState } from "react";
import { Heart } from "react-feather";
import { ListingCompactView } from "../../api/interfaces/listing";
import { useApi } from "../../api/useApi";
import { ListingPanel } from "../components/listing/ListingPanel";
import { LoadingIndicator } from "../components/LoadingIndicator";
import { InfoIcon } from "../components/InfoIcon";
import { FavoriteIcon } from "../components/listing/FavoriteIcon";

export const Favorites = () => {
  const [isLoading, setIsLoading] = useState(true);
  const apiContext = useApi();
  const [favorites, setFavorites] = useState<ListingCompactView[]>([]);
  const context = useApi();

  useEffect(() => {
    const fetchData = async () => {
      const data = await context?.getFavorites();
      setFavorites(data ?? []);
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, []);

  const handleAddToFavorites = async (listingKey: string): Promise<void> => {
    setIsLoading(true);
    await apiContext?.addToFavorites(listingKey);
    setFavorites(
      favorites.map((l) => {
        if (l.listing_key === listingKey) {
          l.favorite = true;
        }
        return l;
      })
    );
    setIsLoading(false);
  };

  const handleRemoveFromFavorites = async (
    listingKey: string
  ): Promise<void> => {
    setIsLoading(true);
    await apiContext?.removeFromFavorites(listingKey);
    setFavorites(
      favorites.map((l) => {
        if (l.listing_key === listingKey) {
          l.favorite = false;
        }
        return l;
      })
    );
    setIsLoading(false);
  };
  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="mainCol">
      <h2>
        Favorites{" "}
        <InfoIcon
          placement='bottom'
          label='Your favorite listings will open in your Preferred Listings Site (see settings)'
        />
      </h2>

      {favorites.map((l) => {
        return (
          <div style={{ position: "relative" }} key={l.listing_key}>
            <ListingPanel
              listing={l}
              listingAction={
                <FavoriteIcon
                  favorite={l.favorite}
                  loading={false}
                  onClick={() =>
                    l.favorite
                      ? handleRemoveFromFavorites(l.listing_key)
                      : handleAddToFavorites(l.listing_key)
                  }
                />
              }
            />
          </div>
        );
      })}
    </div>
  );
};
