import { Marker } from "@vis.gl/react-google-maps";
import { useMemo } from "react";
import { jitter } from "../../utils";

export enum MapMarkerType {
  Search,
  Comparables,
  Main,
}

interface Props {
  lat: number;
  lng: number;
  markerKey: string;
  main?: boolean;
  selected?: boolean;
  onClick: (markerKey: string) => void;
  type: MapMarkerType;
}


export const MapMarker = (props: Props) => {
  const coordinates = useMemo(() => {
    return jitter(props.lat, props.lng, 0.005);
  }, [props.lat, props.lng]);

  let icon = "",
    iconSelected = "",
    iconSize = [0, 0];
  if (props.type === MapMarkerType.Comparables) {
    icon = "/images/marker_comparable.png";
    iconSelected = "/images/marker_comparable_selected.png";
    iconSize = [16, 16];
  } else if (props.type === MapMarkerType.Main) {
    icon = "/images/marker_main.png";
    iconSelected = "/images/marker_main.png";
    iconSize = [16, 16];
  } else if (props.type === MapMarkerType.Search) {
    icon = "/images/marker_search.png";
    iconSelected = "/images/marker_search_active.png";
    iconSize = [25, 51];
  }

  return (
    <>
      <Marker
        onClick={() => props.onClick(props.markerKey)}
        zIndex={props.main ? 9999 : 1}
        animation={google.maps.Animation.DROP}
        position={coordinates}
        icon={{
          url: props.selected ? iconSelected : icon,
          scaledSize: new google.maps.Size(iconSize[0], iconSize[1]),
        }}
      />
    </>
  );
};
