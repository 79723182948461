import styled from "styled-components";

interface Props {
  label: string;
  value: string;
  disabled?: boolean;
  selectedSort: string;
  setSorting: (val: string) => void;
}

const SortingButton = styled.button<{ $selected?: boolean; }>`
    background: none;
    border: none;
    text-wrap: nowrap;
    font-weight: bold;
    padding: 0;
    border-bottom: ${props => props.$selected ? "1px solid" : "none"};
`

export const SortableTitle = ({ selectedSort, label, value, setSorting, disabled }: Props) => {
  const selected = selectedSort.endsWith(value);

  const setCurrentSorting = (val: string) => {
    const order = selected && selectedSort[0] === "-" ? '' : '-'
    setSorting(`${order}${value}`)
  }
  return (
    <SortingButton $selected={selected} disabled={disabled} onClick={() => setCurrentSorting(value)}>{label} {selected && (selectedSort[0] === "-" ? "▼" : "▲")}</SortingButton>
  );
};
