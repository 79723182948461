import {  Image } from "react-bootstrap";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const responsive = {
  0: { items: 3 },
};


interface Props {
  media: any[]
}

export const Carousel = ({media}: Props) => {
  
  const items = media?.map((item, i) => (
    <div className='item' data-value={i + 1} key={item.MediaKey}>
      <a href={item.MediaURL} target="_blank"><Image src={item.MediaURL} height={90} /></a>
    </div>
  ));

  return (
    <div style={{ width: 526 }} className="mt-3">
    <AliceCarousel
        disableDotsControls
        items={items}
        responsive={responsive}
        controlsStrategy='alternate'
    />
    </div>
  );
};
