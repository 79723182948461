import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { LoadingIndicator } from "./LoadingIndicator";

interface Props extends React.ComponentPropsWithoutRef<"button"> {
  label: React.ReactNode;
  callback: () => Promise<any>;
}

export const LoadingButton = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleAsyncCallback = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await props.callback();
      return response;
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Button
      title={props.title}
      style={props.style}
      className={props.className}
      variant='primary'
      type='submit'
      onClick={handleAsyncCallback}
      disabled={isLoading || props.disabled}
    >
      {isLoading ? (
        <>
          <LoadingIndicator />
          &nbsp; Loading...
        </>
      ) : (
        props.label
      )}
    </Button>
  );
};
