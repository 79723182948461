export const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

export const PercentDecimal = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
  useGrouping: false,
});

export const PercentDecimalDouble = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  useGrouping: false,
});



export const formatPercent = (val: number) => {
  return PercentDecimal.format(val) + "%";
};

export const formatPercentDecimal = (val: number) => {
  return PercentDecimalDouble.format(val) + "%";
};

const rad_Earth = 6378.16;
const one_degree = (2 * Math.PI * rad_Earth) / 360;
const one_km = 1 / one_degree;

function randomInRange(from: number, to: number, fixed: number = 10): number {
  return parseFloat((Math.random() * (to - from) + from).toFixed(fixed));
}

export const jitter = (lat: number, lng: number, kms: number) => {
  return {
    lat: randomInRange(lat - kms * one_km, lat + kms * one_km),
    lng: randomInRange(lng - kms * one_km, lng + kms * one_km),
  };
};
