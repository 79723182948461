import { useRef, useState } from "react";
import { ListingCompactView } from "../../../api/interfaces/listing";
import {
  Col,
  Row,
  Form,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import styled from "styled-components";
import { Copy, Check } from "react-feather";
import { InfoIcon } from "../InfoIcon";

interface Props {
  comparables: ListingCompactView[];
  onAdd: (comparablesIds: string[]) => Promise<void>;
}



const MlsInput = styled(Form.Control)`
  border: 1px solid #e4e4e4;
  background: none;
  color: #c1bfbb;

  &::placeholder {
    color: #c1bfbb;
  }
`;
const MlsSubmit = styled.button`
  border: 1px solid #4286f7;
  background: none;
  color: #4286f7;
  border-radius: 5px;
  padding: 0.375rem 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
`;

const IconButton = styled.button`
  border: none;
  background: none;
  padding: 0px;

  svg {
    color: #b2b2b2;
    max-height: 15px;
  }

  &.copy {
    position: absolute;
    right: 5px;
    top: 5px;
  }
`;
const ComparablesTitle = styled.div`
  text-transform: uppercase;
  color: #8c8c8c;
  font-size: 14px;
`;

const Wrapper = styled.div`
  position: relative;
`

export function AddComparable(props: Props) {
  const [mlsValue, setMlsValue] = useState<string>("");
  const [copied, setCopied] = useState(false);
  const [errorAddingMLS, setErrorAddingMLS] = useState(false);
  const targetCopy = useRef(null);
  const { comparables } = props;



  const handleAddComparable = async (
    e: React.FormEvent<HTMLFormElement>,
    listingKey: string
  ): Promise<void> => {
    e.preventDefault();
    if (listingKey === "" || !listingKey) {
      return;
    }
    const comparablesIds = [
      listingKey,
      ...comparables
        .filter((l) => l.enabled !== false)
        .map((l) => l.listing_key),
    ];
    try {
      setMlsValue("");
      setErrorAddingMLS(false);
      await props.onAdd(comparablesIds);
    } catch (error) {
      setErrorAddingMLS(true);
    }
  };

  const copyToClipboard = async () => {
    const listingIds = comparables.map((c) => c.listing_id).join(",");
    await navigator.clipboard.writeText(listingIds);
    setCopied(true);
  };

  if (comparables.length === 0) {
    return null
  }

  return (
    <Wrapper>
      <ComparablesTitle className='mb-2'>
        properties used for rent estimate{" "}
        <InfoIcon label='Update the rent estimate by adding or deleting comparables below' />
      </ComparablesTitle>

      <OverlayTrigger
        placement='right'
        delay={{ show: 250, hide: 400 }}
        overlay={(props) => (
          <Tooltip {...props}>
            <p>Click to copy MLS #s to clipboard</p>
          </Tooltip>
        )}
      >
        <IconButton
          className='copy'
          ref={targetCopy}
          onClick={() => copyToClipboard()}
        >
          {copied ? <Check /> : <Copy />}
        </IconButton>
      </OverlayTrigger>

      {/* <Form onSubmit={(e) => handleAddComparable(e, mlsValue)} className='mb-1'>
        <Row>
          <Col xs={6}>
            <MlsInput
              type='text'
              required
              isInvalid={errorAddingMLS}
              placeholder='Add New Mls'
              value={mlsValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setMlsValue(e.target.value)
              }
            />
            <Form.Control.Feedback type='invalid'>
              MLS # for rental properties only
            </Form.Control.Feedback>
          </Col>
          <Col xs={6}>
            <MlsSubmit type='submit'>Add Comparable Listing</MlsSubmit>
          </Col>
        </Row>
      </Form> */}
    </Wrapper>
  );
}
