import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { formatPercentDecimal, USDollar } from "../../utils";
import { useApi } from "../../../api/useApi";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LoadingIndicator } from "../../components";
import { MapContainer } from "../../components/listing/Map";
import {
  Estimation,
  ListingCompactView,
} from "../../../api/interfaces/listing";
import { SearchTypeAhead, Widget } from "./components";

interface PropertyData {
  community_name: string;
  property_type: string;
  bedrooms: number;
  bathrooms: number;
  year_built: number;
  square_feet: number;
  legal: string;
  ownership1: string;
  ownership2: string;
  mailing_address: string;
  city: string;
  postal_code: string;
  latitude: number;
  longitude: number;
  offer_price: number;
  rent_estimation: number;

  hoa_fee: number | null;
  street_name: string | null;
  street_number: string | null;
  complete_address: string | null;
  unit_number: string | null;
  listing_type: string | null;
}

const Wrapper = styled.div`
  background: rgba(212, 212, 249, 0.25);
  border-radius: 8px;
  padding: 20px;
  font-size: 15px;
  text-transform: uppercase;
  overflow-y: scroll;
  height: calc(97vh - 174px);
`;

const Value = styled.div`
  color: #fff;
`;

const HQWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const PropertyView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const address: string = location.state.address;
  const apiContext = useApi();
  const [propertyData, setPropertyData] = useState<PropertyData | null>(null);
  const [comparables, setComparables] = useState<ListingCompactView[]>([]);
  const [estimation, setEstimation] = useState<Estimation | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { propertyId } = useParams();
  const [selectedPropertyId, setSelectedPropertyId] = useState<
    string | undefined
  >(propertyId);

  const fetchData = async (propertyId: string) => {
    setLoading(true);
    const response = await apiContext?.getPropertyMetadata(propertyId);
    let offer_price = 0
    try {
      const estimationData = await apiContext?.getPropertyEstimationMetadata(
        propertyId
      );
      offer_price = estimationData["summary"]["estimatedValue"]
    } catch { }
    const property = {
      latitude: response["property"]["latitude"],
      longitude: response["property"]["longitude"],
      community_name: response["location"]["subdivision"],
      property_type: response["site"]["landUse"],
      bedrooms: response["building"]["bedrooms"],
      bathrooms: response["building"]["totalBaths"],
      year_built: response["building"]["yearBuilt"],
      square_feet: response["building"]["livingArea"],
      ownership1: response["ownership"]["ownerName1"],
      ownership2: response["ownership"]["ownerName2"],
      legal: response["location"]["legalDescription"],
      mailing_address: `${response["ownership"]["mailingAddress"]} ${response["ownership"]["mailingCityState"]}`,
      city: response["property"]["city"],
      postal_code: response["property"]["zipcode"],
      hoa_fee: null,
      street_name: null,
      street_number: null,
      complete_address: null,
      unit_number: null,
      listing_type: null,
      offer_price: offer_price,
      rent_estimation: 0
    };
    const estimationObj = await apiContext?.getCustomRentomatic(property, []);
    if (estimationObj?.estimation) {
      property.rent_estimation = estimationObj?.estimation.rent_prediction
    }
    setComparables(estimationObj?.comparables!);
    setEstimation(estimationObj?.estimation!);
    setPropertyData(property);
    setLoading(false);
  };

  useEffect(() => {
    if (selectedPropertyId) {
      fetchData(selectedPropertyId!);
    }
  }, [selectedPropertyId]);

  const rentomaticLink = `/rentomatic/${encodeURIComponent(
    JSON.stringify(propertyData)
  )}`;

  const proformatorLink = `/proforma/${encodeURIComponent(
    JSON.stringify(propertyData)
  )}`;

  console.log(rentomaticLink)

  return (
    <>
      <HQWrapper>
        <Row>
          <Col>
            <SearchTypeAhead onChange={setSelectedPropertyId} value={address} />
          </Col>
        </Row>
      </HQWrapper>
      {(propertyData === null || loading) && <LoadingIndicator />}
      {!loading && propertyData && (
        <Wrapper className='mt-3'>
          <Container>
            <Row className='mt-3'>
              <Col>
                <Widget
                  title={USDollar.format(propertyData.rent_estimation)}
                  subtitle='per month'
                  image={"/images/module_icons/rent-o-matic.png"}
                  redirect={() => navigate(rentomaticLink)}
                />
              </Col>
              <Col>
                <Widget
                  title={formatPercentDecimal(
                    estimation?.return_prediction ?? 0
                  )}
                  subtitle='per year'
                  image={"/images/module_icons/proformanator-logo.png"}
                  redirect={() => navigate(proformatorLink)}
                />
              </Col>
              <Col>
                <Widget
                  title='Ask a Pro'
                  subtitle='1 Hour Agent on Demand'
                  image={"/images/module_icons/agent-on-demand.jpeg"}
                  redirect={() => window.open("https://www.selfrealty.com/services/agent-on-demand", "_blank")}
                />
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col xs={3}>
                <div>Community</div>
                <Value>{propertyData.community_name}</Value>
              </Col>
              <Col xs={2}>
                <div>Type</div>
                <Value>{propertyData.property_type}</Value>
              </Col>
              <Col xs={1}>
                <div>Beds</div>
                <Value>{propertyData.bedrooms}</Value>
              </Col>
              <Col xs={1}>
                <div>Baths</div>
                <Value>{propertyData.bathrooms}</Value>
              </Col>
              <Col xs={1} className='alignRight'>
                <div>Yr.Blt</div>
                <Value>{propertyData.year_built}</Value>
              </Col>
              <Col xs={2} className='alignRight'>
                <div>sqft</div>
                <Value>{propertyData.square_feet}</Value>
              </Col>
              <Col xs={2} className='alignRight'>
                <div>est. price</div>
                <Value>
                  {propertyData.offer_price !== null && USDollar.format(propertyData.offer_price)}
                </Value>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col xs={4}>
                <div>LEGAL DESCRIPTION</div>
                <Value>{propertyData.legal}</Value>
              </Col>
              <Col xs={4}>
                <div>OWNERship</div>
                <Value>
                  {propertyData.ownership1}
                  <br />
                  {propertyData.ownership2}
                </Value>
              </Col>
              <Col xs={4} className='alignRight'>
                <div>Mailing address</div>
                <Value>{propertyData.mailing_address}</Value>
              </Col>
            </Row>
          </Container>

          <div className='mt-4'>
            <MapContainer
              onMarkerSelect={console.log}
              comparables={comparables}
              startingLat={propertyData.latitude}
              startingLng={propertyData.longitude}
              startingKey={"custom"}
            />
          </div>
        </Wrapper>
      )}
    </>
  );
};
