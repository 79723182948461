import { ReactNode } from "react";
import { Form, FormControlProps, InputGroup } from "react-bootstrap";
import { PercentDecimalDouble } from "../../../utils";

interface Props extends FormControlProps {
  onUpdate: (value: number) => void;
}


export const Price = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 0,
});

export const formatPercent = (num: number) => {
  return Math.round(num * 100) / 100;
}



export const formatNumericValue = (value: number): ReactNode => {
  if (value < 0) {
    return (
      <span className='redColor'>{`$ (${Price.format(Math.abs(value))})`}</span>
    );
  } else {
    return `$ ${Price.format(Math.abs(value))}`;
  }
};

export const formatEquity = (value: number): ReactNode => {
  let result = PercentDecimalDouble.format(Math.abs(value));
  result += "%";
  if (value < 0) {
    return <span className='redColor'>{`(${result})`}</span>;
  }
  return result;
};

export const PriceInput = (props: Props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.startsWith('-')) {
      e.preventDefault()
      return
    }
    const value = parseInt(e.target.value);
    if (Number.isNaN(value)) {
      props.onUpdate && props.onUpdate(0);
      return;
    }
    props.onUpdate && props.onUpdate(value);
  };

  let value: any;
  if (props.value && typeof props.value === "number") {
    value = Math.round(props.value);
  } else {
    value = props.value;
  }
  return (
    <InputGroup style={{ width: 90 }}>
      <InputGroup.Text>$</InputGroup.Text>
      <Form.Control
        style={{ display: "inline-block", borderLeft: "none" }}
        {...props}
        min={0}
        value={value}
        type='number'
        onChange={handleChange}
      />
    </InputGroup>
  );
};
