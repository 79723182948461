import { RefreshCw } from "react-feather"
import styled from "styled-components"

interface Props {
    onReset: () => void
    filters: Record<string, number|null>
    defaultFilters: Record<string, number|null>
}

const SearchManagementBtn = styled.button`
    background: none;
    border: none;
    color: #D4D4F9;
    padding: 0;

    &:disabled {
        opacity: 0.5
    }
`

export const SearchManagement = ({filters, defaultFilters, onReset}: Props) => {
    const noFiltersChange = Object.keys(filters).every((key) => filters[key] === defaultFilters[key])
    return (
        <div className='mb-2'>
            {/* <SearchManagementBtn disabled={noFiltersChange} onClick={onReset}><RefreshCw /></SearchManagementBtn> */}
        </div>
    )
}