import { useNavigate, useLocation, Link } from "react-router-dom";
import { Button, Image } from "react-bootstrap";
import { LogIn, Grid } from "react-feather";
import { useAuth } from "../hooks/useAuth";
import styled from "styled-components";
import { useModules } from "../pages/dashboard/module.hook";

const MenuLink = styled.a`
  font-size: 12px;
  text-align: center;
  display: block;
`;

interface Props {
  setShowFeedbackForm: (show: boolean) => void;
}

const Menu = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const auth = useAuth();
  const [modules] = useModules();

  return (
    <>
      {auth?.userToken ? (
        <>
          <Button
            className='mb-2 menuBtn'
            active={location.pathname.startsWith("/dashboard")}
            onClick={() => navigate("/dashboard")}
            title='Dashboard'
          >
            <Grid />
          </Button>

          {modules.map((module) => (
            <Link
              key={module.moduleId}
              to={module.added ? module.url : '/dashboard'}
              className={`mb-2 menuBtn ${!location.pathname.startsWith(module.url) ? 'moduleBtnDisabled' : ''}`}
              title={module.title}
            >
              <Image src={module.imagePath} />
            </Link>
          ))}
        </>
      ) : (
        <Button
          className='mb-2 menuBtn'
          active={location.pathname.startsWith("/accounts/login")}
          onClick={() => navigate("/accounts/login")}
          title='Login'
        >
          <LogIn />
        </Button>
      )}

      <MenuLink
        onClick={(e) => {
          e.preventDefault();
          props.setShowFeedbackForm(true);
        }}
        href='#'
      >
        Feedback
      </MenuLink>
    </>
  );
};

export default Menu;
