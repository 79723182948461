import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Sidebar } from "./Sidebar";

interface Props {
  center: React.ReactNode;
  sidebar: React.ReactNode;
}

export const ContentWithSideBar = (props: Props) => {
  return (
    <Row>
      <Col>
        
       {props.center}
        
      </Col>
      <Sidebar>{props.sidebar}</Sidebar>
    </Row>
  );
};
