import { useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { useApi } from "../../../api/useApi";
import { Listing, ListingCompactView } from "../../../api/interfaces/listing";
import { ListingDetails } from "../../components/listing";

export const Default = () => {
  //@ts-ignore
  const { logCustomError, currentUrl } = useOutletContext();
  logCustomError("Not found");

  const apiContext = useApi();
  const [listings, setListings] = useState<ListingCompactView[]>([]);
  const [listingsFull, setListingsFull] = useState<Listing[]>([]);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  const handleLoadRandomListings = async () => {
    const data = await apiContext?.getListings(
      {
        property_type: "Residential",
        standard_status: "Active",
        days_on_market_min: 7,
        days_on_market_max: 90,
        list_price_min: 600000,
        list_price_max: 1200000,
        property_subtype: ["Single Family Residence"],
        lot_size_area_max: 0.5,
      },
      0,
      null,
      3
    );
    const results = await Promise.all(
      // @ts-ignore
      data.results.map((x) => apiContext?.getListing(x.listing_key))
    );
    try {
      setListingsFull(results);
      setListings(data.results);
    } catch (error) {}
  };

  useEffect(() => {
    setInitialLoading(true);
    handleLoadRandomListings()
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setInitialLoading(false);
      });
  }, []);

  const getPortalUrl = (listingKey: string): string => {
    const listing = listingsFull.find((x) => x.listing_key === listingKey);
    if (listing) {
      const allUrl = [
        listing.external_url_zillow,
        listing.external_url_realtor,
        listing.external_url_redfin,
        "https://www.zillow.com"
      ].filter((url) => url !== null);

      if (currentUrl.startsWith("https://www.zillow.com")) {
        return listing.external_url_zillow ?? allUrl[0] ?? "";
      }
      if (currentUrl.startsWith("https://www.realtor.com")) {
        return listing.external_url_realtor ?? allUrl[0]?? "";
      }
      if (currentUrl.startsWith("https://www.redfin.com")) {
        return listing.external_url_redfin ?? allUrl[0]?? "";
      }
      return listing.external_url_zillow ?? allUrl[0]?? "";
    }
    return "";
  };

  return (
    <>
      <div style={{ fontSize: 16, paddingBottom: 20 }}>
        <p>
          Uh-oh! We can't determine an active listing address on this page. SELF
          Realty currently serves the Greater "Texas Triangle", including
          Austin, Dallas, Fort Worth, Houston, and San Antonio. If this is an
          error you can let us know using the quick "Feedback" link in menu bar.
        </p>
      </div>

      {initialLoading && <LoadingIndicator />}
      <div style={{ margin: "0 auto", width: "500px" }}>
        {!initialLoading && <h2>Try these Listings</h2>}
        {listings.map((x) => (
          <div className='mb-3' key={x.listing_key}>
            {getPortalUrl(x.listing_key) && <Link target='_blank' to={getPortalUrl(x.listing_key)}>
              <ListingDetails listing={x} />
            </Link>}
            
          </div>
        ))}
      </div>

      <hr />
      <p>
        <a href='https://www.selfrealty.com/start' target='_blanks'>
          <b>Quick Start</b>
        </a>
      </p>
    </>
  );
};
