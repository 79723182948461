import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/types";
import { Info } from "react-feather";
import { styled } from "styled-components";

interface Props {
  label: string;
  placement?: Placement
}

const FixedIcon = styled(Info)`
  width: 1rem;
  margin-top: -3px;
  color: auto;
`;

export function InfoIcon({ label, placement = 'right' }: Props) {
  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 250, hide: 400 }}
      overlay={<Tooltip placement='right'>{label}</Tooltip>}
    >
      <FixedIcon />
    </OverlayTrigger>
  );
}
