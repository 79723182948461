import { useOutletContext } from "react-router-dom";

export const SearchPage = () => {
  //@ts-ignore
  const { logCustomError } = useOutletContext();
  logCustomError("Search page not supported");

  return (
    <>
      <div style={{ fontSize: 16 }}>
        <p>
          Doh! Looks like you’re still searching for the right property. SELF
          works once you’re looking at a single listing.
        </p>
      </div>

      <hr />
      <p>
        <a href='https://www.selfrealty.com/start' target='_blanks'>
          <b>Quick Start</b>
        </a>
      </p>
    </>
  );
};
