import { Heart } from "react-feather";
import { styled } from "styled-components";

const Favorite = styled.button`
  border: none;
  padding: 0;
  background: none;

  svg {
    width: 20px;
  }
`;

interface Props {
  favorite: boolean;
  loading: boolean;
  onClick: () => Promise<void>;
}

export const FavoriteIcon = (props: Props) => {
  if (props.favorite) {
    return (
      <Favorite
        title='Remove from favorites'
        disabled={props.loading}
        onClick={props.onClick}
      >
        <Heart fill='#FF6058' color='#FF6058' />
      </Favorite>
    );
  }

  return (
    <Favorite
      title='Add to favorites'
      disabled={props.loading}
      onClick={props.onClick}
    >
      <Heart />
    </Favorite>
  );
};
