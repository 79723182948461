import { useState } from "react";
import { Proforma } from "../../../../api/interfaces/proforma";

export const useProforma = (data: Proforma) => {
  
  const nextMonthDate = new Date();
  nextMonthDate.setDate(nextMonthDate.getDate() + 30);

  const [offerPrice, setOfferPrice] = useState<number>(data.offer_price);

  
  const [closingDate, setClosingDate] = useState<string>(data.closing_date ?? nextMonthDate.toISOString().slice(0, 10));
  const [rentEstimate, setRentEstimate] = useState<number>(data.rent_estimate);
  const [closingCosts, setClosingCosts] = useState<number>(
    data.closing_costs ?? (data.offer_price / 100) * data.closing_costs_rate
  );

  const [downPayment, setDownPayment] = useState<number>(
    data.down_payment ??
      Math.round(data.offer_price * (data.down_payment_rate / 100))
  );
  const [hoaTransfer, setHoaTransfer] = useState<number>(
    data.hoa_transfer ?? 0
  );
  const [propertyTaxProration, setPropertyTaxProration] = useState<number>(0);

  const [repairAndMaintenance, setRepairAndMaintenance] = useState<
    number | null
  >(data.repair_and_maintenance);

  const [propertyManagement, setPropertyManagement] = useState<number | null>(
    data.property_management
  );
  const [listingFees, setListingFees] = useState<number | null>(
    data.listing_fees
  );
  const [hoaFee, setHoaFee] = useState<number | null>(null);
  const [propertyTax, setPropertyTax] = useState<number | null>(
    data.property_tax
  );
  const [insurance, setInsurance] = useState<number | null>(data.insurance);
  const [other, setOther] = useState<number | null>(data.other);

  return {
    providerEstimatedTaxes: data.provider_estimated_taxes,
    providerAssosiationFee: data.hoa_fee ?? 0,
    downPayment,
    setDownPayment,

    propertyTaxProration,
    setPropertyTaxProration,
    
    closingDate,
    setClosingDate,

    rentEstimate,
    setRentEstimate,

    closingCosts,
    setClosingCosts,

    hoaTransfer,
    setHoaTransfer,

    offerPrice,
    setOfferPrice,

    propertyTax,
    setPropertyTax,

    hoaFee,
    setHoaFee,

    repairAndMaintenance,
    setRepairAndMaintenance,

    propertyManagement,
    setPropertyManagement,

    listingFees,
    setListingFees,

    insurance,
    setInsurance,

    other,
    setOther,
  };
};
