import { useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../../api/useApi";
import { LoadingIndicator } from "../../components/LoadingIndicator";

export const Logout = () => {
    const navigate = useNavigate();
    const context = useApi();
    const handleLogout = async () => {
        await context?.logout();
        navigate("/accounts/login", { replace: true });
    };

    useEffect(() => {
        handleLogout()
    }, [])

    return <LoadingIndicator/>
}