import { APIProvider, Map, MapEvent } from "@vis.gl/react-google-maps";
import { useDebounce } from "@uidotdev/usehooks";
import { Col, Row } from "react-bootstrap";
import { Sidebar } from "../../components/layout";
import { useApi } from "../../../api/useApi";
import { useEffect, useState } from "react";
import { Listing, ListingCompactView } from "../../../api/interfaces/listing";
import Table from "react-bootstrap/Table";
import { FavoriteIcon } from "../../components/listing/FavoriteIcon";
import { styled } from "styled-components";
import Pagination from "../../components/Pagination";
import { useSearchParams } from "react-router-dom";
import { MapMarker, MapMarkerType } from "../../components/layout/MapMarker";
import { InfoTooltip } from "../../components/InfoTooltip";
import { InfoIcon } from "../../components/InfoIcon";
import { useOutletContext } from "react-router-dom";

import {
  ListingExpanded,
  CreamUsage,
  SelectedProperty,
  SearchFilters,
  SearchManagement,
} from "./components";
import { LoadingIndicator } from "../../components";
import { SortableTitle } from "./components/SortableTitle";
import { PercentDecimalDouble, USDollar } from "../../utils";

const styles = [
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        lightness: 100,
      },
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

const CreamHeader = styled.div`
  font-size: 16px;
  line-height: 44px;
  height: 44px;
  color: #fff;
  position: absolute;
  top: -44px;
`;

const TableWrapper = styled.div`
  height: calc(97vh - 453px);
  overflow-y: scroll;
  margin-bottom: 10px;
`;

const StyledTable = styled(Table)`
  tr,
  td {
    border-color: #ebebfb !important;
    text-align: left;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  & tr.active td {
    background: rgba(235, 235, 251, 0.5);
    border: none;
    font-weight: bold !important;
  }
  & tr:hover td {
    background: rgba(235, 235, 251, 0.5) !important;
  }
`;

const TdText = styled.td`
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
  max-width: 200px;
`;

const CreamSearch = styled.div`
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  width: 566px;
`;

interface Offset {
  value: number;
}

interface BBox {
  min_lon: number;
  min_lat: number;
  max_lon: number;
  max_lat: number;
  zoom_level: number;
}

export const CreamWrapper = () => {
  const limitPerPage = 10;
  const defaultFilters = {
    estimated_rent_min: 0,
    estimated_return_min: 0,
    list_price_min: 100000,
    list_price_max: 700000,
  };
  // @ts-ignore
  const { creamUsageAccepted, setCreamUsageAccepted } = useOutletContext();
  const [activeListing, setActiveListing] = useState<Listing | undefined>(
    undefined
  );
  const [activeListingScore, setActiveListingScore] = useState<number | null | undefined>(
    null
  );

  const apiContext = useApi();
  const [listings, setListings] = useState<ListingCompactView[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [offset, setOffset] = useState<Offset>({ value: 0 });
  const [sortBy, setSortBy] = useState<string>("-return");
  const [filters, setFilters] =
    useState<Record<string, number | null>>(defaultFilters);
  const [bbox, setBBox] = useState<BBox>({
    max_lat: 30.016087366800104,
    max_lon: -94.93810212637138,
    min_lat: 29.658713704344052,
    min_lon: -95.71538484121513,
    zoom_level: 10,
  });
  const [searchParams] = useSearchParams();
  const context = useApi();

  const onChange = (event: MapEvent) => {
    const bounds = event.map.getBounds();
    if (!bounds) return;
    const { south, north, east, west } = bounds.toJSON();
    setBBox({
      min_lon: west,
      min_lat: south,
      max_lon: east,
      max_lat: north,
      zoom_level: event.map.getZoom() ?? 10,
    });
  };

  const debouncedFilters = useDebounce(filters, 300);
  const debouncedBbox = useDebounce(bbox, 300);

  const fetchData = async () => {
    if (!filters) return;
    console.log(offset, filters);
    const data = await context?.getListings(
      {
        property_type: "Residential",
        standard_status: "Active",
        ...filters,
        ...bbox,
      },
      offset.value,
      sortBy,
      limitPerPage
    );
    setListings(data.results);
    setTotal(data.count);
  };

  useEffect(() => {
    const newOffset = parseInt(searchParams.get("offset") ?? "0");
    setOffset({ value: newOffset });
  }, [searchParams]);

  useEffect(() => {
    setOffset({ value: 0 });
  }, [debouncedFilters, debouncedBbox]);

  useEffect(() => {
    context?.getUserData().then((res) => {
      setIsAdmin(res.is_superuser);
    });
  }, []);

  useEffect(() => {
    if (!creamUsageAccepted) return;
    unsetActiveListing();
    fetchData().catch(() => {
      setListings([]);
    });
  }, [offset, sortBy, creamUsageAccepted]);

  const handleAddToFavorites = async (listingKey: string): Promise<void> => {
    if (apiContext?.isLoading) return;
    await apiContext?.addToFavorites(listingKey);
    setListings(
      listings.map((l) => {
        if (l.listing_key === listingKey) {
          l.favorite = true;
        }
        return l;
      })
    );
  };

  const handleRemoveFromFavorites = async (
    listingKey: string
  ): Promise<void> => {
    if (apiContext?.isLoading) return;
    await apiContext?.removeFromFavorites(listingKey);
    setListings(
      listings.map((l) => {
        if (l.listing_key === listingKey) {
          l.favorite = false;
        }
        return l;
      })
    );
  };

  const disableListing = async (listingKey: string): Promise<void> => {
    await apiContext?.disableListing(listingKey);
    setListings(
      listings.filter((l) => {
        if (l.listing_key === listingKey) {
          return false;
        }
        return true;
      })
    );
    unsetActiveListing()
  };

  const unsetActiveListing = () => {
    setActiveListing(undefined);
    setActiveListingScore(undefined)
  }

  const loadListing = async (listingKey: string): Promise<void> => {
    if (listingKey === activeListing?.listing_key) {
      unsetActiveListing()
      return;
    }
    const creamScore = listings.find(x => listingKey === x.listing_key)?.estimation?.cream_score
    const listing = await apiContext?.getListing(listingKey);
    setActiveListing(listing);
    setActiveListingScore(creamScore)
  };
  return (
    <>
      <Row>
        <Col>
          {/* <SubjectListing /> */}
          <CreamHeader>
            Drag and zoom map to your preferred location{" "}
            <InfoIcon
              placement='right'
              label='SELF Realty currently serves Greater Austin, Dallas, Fort Worth, Houston, and San Antonio.'
            />
          </CreamHeader>
          <CreamSearch>
            <APIProvider apiKey={"AIzaSyAafNtCtx8Q7fh6bbFQBi4bq4Br-mzVY9g"}>
              <Map
                // onBoundsChanged={onChange}
                onDragend={onChange}
                style={{
                  height: 300,
                }}
                mapTypeId={"terrain"}
                mapTypeControl={false}
                styles={styles}
                defaultBounds={{
                  south: bbox.min_lat,
                  west: bbox.min_lon,
                  north: bbox.max_lat,
                  east: bbox.max_lon,
                }}
                streetViewControl={false}
                maxZoom={16}
                minZoom={6}
              >
                {listings.map((listing) => (
                  <MapMarker
                    type={MapMarkerType.Search}
                    selected={
                      activeListing?.listing_key === listing.listing_key
                    }
                    onClick={() => loadListing(listing.listing_key)}
                    key={listing.listing_key}
                    lat={listing.latitude}
                    lng={listing.longitude}
                    markerKey={listing.listing_key}
                  />
                ))}
              </Map>
            </APIProvider>
            {!creamUsageAccepted && (
              <CreamUsage onAccept={() => setCreamUsageAccepted(true)} />
            )}
            {listings.length > 0 && creamUsageAccepted && (
              <>
                <TableWrapper>
                  <StyledTable>
                    <thead>
                      <tr className='sticky'>
                        <th>Address</th>
                        <th>
                          <SortableTitle
                            disabled={apiContext?.isLoading}
                            setSorting={setSortBy}
                            selectedSort={sortBy}
                            value='beds'
                            label='Beds'
                          />
                        </th>
                        <th>
                          <SortableTitle
                            disabled={apiContext?.isLoading}
                            setSorting={setSortBy}
                            selectedSort={sortBy}
                            value='year'
                            label='YrBlt'
                          />
                        </th>
                        <th>
                          <SortableTitle
                            disabled={apiContext?.isLoading}
                            setSorting={setSortBy}
                            selectedSort={sortBy}
                            value='price'
                            label='Price'
                          />
                        </th>
                        <th>
                          <SortableTitle
                            disabled={apiContext?.isLoading}
                            setSorting={setSortBy}
                            selectedSort={sortBy}
                            value='rent'
                            label='Rent'
                          />
                        </th>
                        <th colSpan={2}>
                          <SortableTitle
                            disabled={apiContext?.isLoading}
                            setSorting={setSortBy}
                            selectedSort={sortBy}
                            value='return'
                            label='Return'
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {listings.map((listing) => (
                        <>
                          <tr
                            key={listing.listing_key}
                            onClick={() => loadListing(listing.listing_key)}
                            className={
                              listing.listing_key === activeListing?.listing_key
                                ? "active"
                                : ""
                            }
                          >
                            <TdText>
                              <InfoTooltip
                                label={listing.full_address}
                                placement='top'
                              >
                                {listing.full_address}
                              </InfoTooltip>
                            </TdText>

                            <td>{listing.bedrooms_total}</td>
                            <td>{listing.year_built}</td>
                            <td>{USDollar.format(listing.list_price)}</td>
                            <td>
                              {listing.estimation?.rent_prediction
                                ? USDollar.format(
                                    listing.estimation?.rent_prediction
                                  )
                                : null}
                            </td>
                            <td>
                              {listing.estimation?.return_prediction
                                ? PercentDecimalDouble.format(
                                    listing.estimation.return_prediction
                                  ) + "%"
                                : null}
                            </td>
                            <td>
                              <FavoriteIcon
                                favorite={listing.favorite}
                                loading={false}
                                onClick={() =>
                                  listing.favorite
                                    ? handleRemoveFromFavorites(
                                        listing.listing_key
                                      )
                                    : handleAddToFavorites(listing.listing_key)
                                }
                              />
                            </td>
                          </tr>
                          {listing.listing_key ===
                            activeListing?.listing_key && (
                            <ListingExpanded
                              isAdmin={isAdmin}
                              onListingDisable={disableListing}
                              listing={activeListing}
                            />
                          )}
                        </>
                      ))}
                    </tbody>
                  </StyledTable>
                </TableWrapper>
                <Pagination
                  total={total}
                  offset={offset.value}
                  limit={limitPerPage}
                />
              </>
            )}
          </CreamSearch>
        </Col>
        <Sidebar>
          <SearchManagement
            onReset={() => setFilters(defaultFilters)}
            filters={filters}
            defaultFilters={defaultFilters}
          />
          <SearchFilters
            onChange={(filters) => setFilters(filters)}
            filters={filters}
          />
          <div>
            {apiContext?.isLoading && (
              <>
                <LoadingIndicator /> Loading 10 properties
              </>
            )}
          </div>
          <SelectedProperty
            listing={activeListing}
            creamScore={activeListingScore}
          ></SelectedProperty>
        </Sidebar>
      </Row>
    </>
  );
};
