import styled from "styled-components";
import { useListing } from "../hooks/useListing";
import { Link } from "react-router-dom";

const SubjectListingWrapper = styled.div`
  position: absolute;
  top: -44px;
  font-size: 18px;
  line-height: 44px;
  height: 44px;
  color: #fff;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
  width: 570px;

  a {
    color: #fff;
    text-decoration: none;
  }
    &:hover {
      text-decoration: underline;
    }
`;

export const SubjectListing = () => {
  const listingContext = useListing();
  const listing = listingContext?.listing;
  const listingKey = listingContext?.listingKey;

  if (listing === null) {
    const listingObj = JSON.parse(decodeURIComponent(listingKey!))
    if (listingObj === null) {
      return null
    }
    return (
      <SubjectListingWrapper>
        <Link to={`/rentomatic/${listingKey}`}>
          {listingObj['complete_address']}
          {' '}
          {listingObj.isRental ? " [For Rent]" : " [For Sale]"}
        </Link>
      </SubjectListingWrapper>
    )
  }

  return (
    <SubjectListingWrapper>
      <Link to={`/rentomatic/${listing?.listing_key}`} title="Subject Property">
        {listing?.full_address}
        {listing?.city ? `, ${listing.city}` : ''}
        {listing?.state && listing?.postal_code ? `, ${listing.state} ${listing.postal_code}` : ''}
        {" ["}
        {listing?.property_type === "Residential" ? "For Sale" : "For Rent"}
        {`: ${listing?.standard_status}`}
        {"]"}
      </Link>
    </SubjectListingWrapper>
  );
};
