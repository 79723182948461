import { Link, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useApi } from "../../../api/useApi";
import { Listing } from "../../../api/interfaces/listing";
import { useEffect, useState } from "react";
import { useListing } from "../../hooks/useListing";
import {
  LoadingButton,
  LoadingIndicator,
  SubjectListing,
} from "../../components";
import styled from "styled-components";
import { Button, Col, Image, Row } from "react-bootstrap";
import { Sidebar } from "../../components/layout";
import { ModulePreview } from "../../widgets";
import { Dossier } from "../../../api/interfaces/dossier";
import { OrderBox, OrderList } from "./components";

const DossierWrapper = styled.div`
  background: #fefaf3;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const OrderBoxStyled = styled.div`
  background: #fefaf3;
  border-radius: 8px;
  color: #000;
  padding: 45px;
  width: 566px;
  word-wrap: break-word;

  a {
    color: #000;
  }
`;

const DossierImage = styled.div`
  width: 300px;
  border-radius: 8px;
  overflow-x: hidden;
`;

const DossierInfo = styled.div`
  width: 300px;
  border-radius: 8px;
  border: 1px solid #d4d4f9;
  font-size: 14px;
  color: #000;
  padding: 10px 15px;
`;

const DossierTitle = styled.div`
  font-size: 24px;
  line-height: 24px;
`;

const DossierSubTitle = styled.div`
  font-size: 18px;
`;

const CreditsInfo = styled.p`
  font-size: 10px;
  color: #ffffff;
  padding: 0px 10px;
`;

const CreditsBalance = styled.div`
  color: #d4d4f9;
  font-size: 12px;
  font-weight: light;
`;

export const DossierHome = () => {
  //@ts-ignore
  const { currentUrl } = useOutletContext();
  const apiContext = useApi();
  const navigate = useNavigate();
  const listingContext = useListing();
  const { listingString } = useParams();
  const [dossier, setDossier] = useState<Dossier | null>(null);
  const [listing, setListing] = useState<Listing | null>(null);
  const [order, setOrder] = useState<Dossier | null>(null);
  const [credits, setCredits] = useState<number>(0);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  const listingKey = listingString ?? listingContext?.listingKey;

  const mlsListing = listingKey?.length && listingKey?.length <= 32;

  const handleLoadListing = async (): Promise<void> => {
    const data = await apiContext?.getListing(listingKey!);
    setListing(data);
  };

  const handleLoadDossierRequest = async (): Promise<void> => {
    try {
      const data = await apiContext?.getDossier(listingKey!);
      setDossier(data);
    } catch (error) {
      setDossier(null);
    }
  };

  const handleInitialLoad = async () => {
    const tasks = [handleLoadListing(), handleLoadDossierRequest()];
    return Promise.all(tasks);
  };

  const requestDossier = async () => {
    try {
      const data = await apiContext?.requestDossier(listingKey!, currentUrl);
      setDossier(data);
    } catch (error) {
      setDossier(null);
    }
  };

  useEffect(() => {
    apiContext?.getUserData().then((user) => setCredits(user.credits));
  }, [dossier]);

  useEffect(() => {
    if (listingKey) {
      setInitialLoading(true);
      handleInitialLoad()
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setInitialLoading(false);
        });
    } else {
      setInitialLoading(false);
    }
  }, [listingKey]);



  if (initialLoading || (mlsListing && !listing)) {
    return <LoadingIndicator />;
  }

  if (listingKey === null) {
    navigate('/dashboard')
  }

  if (!mlsListing) {
    return <h2>This property is not currently supported by Dossier</h2>
  }

  if (dossier?.delivered && order === null) {
    setOrder(dossier)
  }

  return (
    <Row>
      <Col>
        <SubjectListing />
        {order !== null && (
          <OrderBoxStyled className="mainCol">
            <OrderBox dossier={order} />
            {order.delivered && order.content && (
              <>
                <div className='mt-3'>
                  <b>Files</b>
                  <br />
                  <a href={order.content} target='_blank'>
                    {order.content}
                  </a>
                </div>
              </>
            )}
          </OrderBoxStyled>
        )}
        {order === null && (
          <DossierWrapper className="mainCol">
            {listing?.media_url && (
              <DossierImage className='mb-4'>
                <Image width={300} src={listing?.media_url} />
              </DossierImage>
            )}
            <DossierInfo>
              <DossierTitle>{listing?.full_address}</DossierTitle>
              <DossierSubTitle className='mb-2'>{`${listing?.city}, ${listing?.state} ${listing?.postal_code}`}</DossierSubTitle>
              <div>
                Source: {listing?.data_source} {listing?.listing_id}
              </div>
              {listing?.last_modified_date && (
                <div>
                  Last Changed:{" "}
                  {new Date(listing.last_modified_date).toLocaleDateString(
                    "en-US"
                  )}
                </div>
              )}
              <div>Listed By: {listing?.list_agent_full_name}</div>
              <div>Brokered By: {listing?.list_office_name}</div>
            </DossierInfo>
          </DossierWrapper>
        )}
      </Col>
      <Sidebar>
        <ModulePreview name='DOSSIER' imageName='dossier.png' className='mb-3'>
          <div className='mt-3'>
            Use DOSSIER for deep reports on any listing.
            <ul className='mt-1'>
              <li>✓ non-public listing disclosures (as available)</li>
              <li>✓ owner & mortgage history</li>
              <li>✓ complete listing history</li>
              <li>✓ homestead status, and more</li>
            </ul>
          </div>
        </ModulePreview>
        {dossier === null && credits > 0 && (
          <>
            <LoadingButton
              label={<b>Order DOSSIER for 1 credit</b>}
              disabled={apiContext?.isLoading}
              callback={requestDossier}
            />
          </>
        )}
        {dossier !== null && (
          <>
            <Button style={{ width: "100%" }} variant='light' disabled>
              Order placed{" "}
              {new Date(dossier.created_at).toLocaleDateString("en-US")}
            </Button>
          </>
        )}
        {credits === 0 && dossier !== null && (
          <>
            <Button
              style={{ width: "100%", backgroundColor: "#FFCF6F" }}
              variant='light'
              onClick={() => navigate("/dashboard")}
            >
              <b>Get More Credits</b>
            </Button>
          </>
        )}
        <CreditsInfo className='mt-4'>
          Results will be emailed to you within 24 hours. 3 credits per month included with PLUS membership. Additional credits can be purchased.
        </CreditsInfo>
        <br />
        <br />
        <CreditsBalance className='mb-2'>
          <div className='fleft'>
            <b>ORDER HISTORY</b>
          </div>
          <div className='fright'>
            {credits} credits remain. <Link to='/dashboard'>Get more.</Link>{" "}
          </div>
          <div className='clear'></div>
        </CreditsBalance>
        <OrderList onSelect={setOrder} />
      </Sidebar>
    </Row>
  );
};
