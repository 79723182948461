import { ListingCompactView } from "../../../api/interfaces/listing";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { Trash } from "react-feather";
import { ListingPanel } from "./ListingPanel";
import { LoadingIndicator } from "../LoadingIndicator";

interface Props {
  selected: string | null;
  loading: boolean;
  size: number;
  editable?: boolean;
  comparables: ListingCompactView[];
  updateComparables?: (
    comparablesIds: string[]
  ) => Promise<void>;
}

const Comparables = styled(Row)`
  max-height: 280px;
  overflow: scroll;
  position: relative;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none
  }

  .disabled {
    opacity: 0.3;
  }
`;

const ComparableColumn = styled(Col)`
  position: relative;
`;

export function RentComparables(props: Props) {
  const { loading, comparables } = props;

  const handleDeleteComparable = async (listingKey: string): Promise<void> => {
    const comparablesIds = [
      ...comparables
        .filter(
          (l) =>
            l.enabled !== false && l.listing_key !== listingKey
        )
        .map((l) => l.listing_key),
    ];
    if (props.updateComparables) {
      await props.updateComparables(comparablesIds);
    }
  };

  const handleAddComparable = async (listingKey: string): Promise<void> => {
    if (listingKey === "" || !listingKey) {
      return;
    }
    const comparablesIds = [
      listingKey,
      ...comparables
        .filter((l) => l.enabled !== false)
        .map((l) => l.listing_key),
    ];
    if (props.updateComparables) {
      await props.updateComparables(comparablesIds);
    }
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Comparables>
        {comparables.map((listing) => {
          return (
            <ComparableColumn
              xs={12 / props.size}
              key={listing.listing_key}
            >
              <ListingPanel
                listing={listing}
                selected={props.selected === listing.listing_key}
                className={!listing.enabled ? "disabled" : ""}
                listingAction={
                  props.editable &&
                  (listing.enabled ? (
                    <button
                      onClick={() =>
                        handleDeleteComparable(listing.listing_key)
                      }
                    >
                      <Trash />
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        handleAddComparable(listing.listing_key)
                      }
                    >
                      Undo
                    </button>
                  ))
                }
              />
            </ComparableColumn>
          );
        })}
      </Comparables>
    </>
  );
}
