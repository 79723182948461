import { useEffect, useState } from "react";
import { useApi } from "../../../../api/useApi";
import { Dossier } from "../../../../api/interfaces/dossier";
import styled from "styled-components";
import { OrderBox } from "./OrderBox";

const OrderBoxStyled = styled.div`
  background: #42465d;
  border-radius: 8px;
  padding: 15px;
  color: #fff;
  font-size: 10px;
  cursor: pointer;

  b {
    font-size: 12px;
  }
`;

const OrderListWrapper = styled.div`
  height: 210px;
  overflow: scroll;
`;

interface Props {
  onSelect: (order: Dossier) => void;
}

export const OrderList = (props: Props) => {
  const [orders, setOrders] = useState<Dossier[]>([]);
  const apiContext = useApi();

  useEffect(() => {
    apiContext?.getDossierList().then((list) => setOrders(list));
  }, []);

  return (
    <OrderListWrapper>
      {orders.map((order) => (
        <OrderBoxStyled
          key={order.id}
          onClick={() => props.onSelect(order)}
          className='mb-2'
        >
          <OrderBox dossier={order} />
        </OrderBoxStyled>
      ))}
    </OrderListWrapper>
  );
};
