import { useLocation, useOutletContext } from "react-router-dom";

export const PropertyNotSupported = () => {
  //@ts-ignore
  const { logCustomError } = useOutletContext();
  const location = useLocation();
  const state = location.state;

  logCustomError(`Property type ${state.propertyType} not supported`);
  return (
    <>
      <div style={{ fontSize: 16 }}>
        <p>
          This property type {state.propertyType} is not currently supported.
        </p>
      </div>

      <hr />
      <p>
        <a href='https://www.selfrealty.com/start' target='_blanks'>
          <b>Quick Start</b>
        </a>
      </p>
    </>
  );
};
