import { useState } from "react";
import { ListingCompactView } from "../../../api/interfaces/listing";
import { Col, Row, Form } from "react-bootstrap";
import styled from "styled-components";

interface Props {
  comparables: ListingCompactView[];
  onAdd: (comparablesIds: string[]) => Promise<void>;
}

const MlsInput = styled(Form.Control)`
  border: 1px solid #e4e4e4;
  background: none;
  color: #c1bfbb;

  &::placeholder {
    color: #c1bfbb;
  }
`;
const MlsSubmit = styled.button`
  border: none;
  background: #4286f7;
  color: #fff;
  border-radius: 5px;
  padding: 0.375rem 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
`;

const Wrapper = styled.div`
  position: relative;
`;

export function AddComparableCompact(props: Props) {
  const [mlsValue, setMlsValue] = useState<string>("");
  const [errorAddingMLS, setErrorAddingMLS] = useState(false);
  const { comparables } = props;

  const handleAddComparable = async (
    e: React.FormEvent<HTMLFormElement>,
    listingKey: string
  ): Promise<void> => {
    e.preventDefault();
    if (listingKey === "" || !listingKey) {
      return;
    }
    const comparablesIds = [
      listingKey,
      ...comparables
        .filter((l) => l.enabled !== false)
        .map((l) => l.listing_key),
    ];
    try {
      setMlsValue("");
      setErrorAddingMLS(false);
      await props.onAdd(comparablesIds);
    } catch (error) {
      setErrorAddingMLS(true);
    }
  };

  return (
    <Wrapper>
      <Form onSubmit={(e) => handleAddComparable(e, mlsValue)} className='mb-1'>
        <Row>
          <Col xs={8}>
            <MlsInput
              type='text'
              required
              isInvalid={errorAddingMLS}
              placeholder='Add New Mls'
              value={mlsValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setMlsValue(e.target.value)
              }
            />
            <Form.Control.Feedback type='invalid'>
              MLS # for rental properties only
            </Form.Control.Feedback>
          </Col>
          <Col xs={4}>
            <MlsSubmit type='submit'>Add</MlsSubmit>
          </Col>
        </Row>
      </Form>
    </Wrapper>
  );
}
