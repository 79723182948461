import { useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { User } from "../../../api/interfaces/user";
import { Header } from "../../widgets";

const LeftCol = styled.div`
  position: relative;
  padding: 10px 10px;
  font-size: 14px;
  color: #fff;

  a {
    text-decoration: none;
  }
`;

const RightCol = styled.div`
  padding: 10px 20px;
  flex: 0 0 350px;
  color: #fff;

  img {
    max-width: 280px;
  }
`;

const Separator = styled.div`
  position: absolute;
  width: 1px;
  background: #d4d4f9;
  right: 0px;
  top: 0px;
  bottom: 60px;
`;

const RelativeCol = styled(Col)`
  position: relative;
`;

const TermsScrollBar = styled.div`
  background: white;
  font-size: 12px;
  color: #000;
  height: 250px;
  overflow: scroll;
  overflow-x: hidden;
  border-radius: 8px;
  padding: 10px;

  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #333;
  }

  a {
    color: #4286f7;
  }
`;


export const AcceptTerms = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user: User = location.state.user;
  const terms: string = location.state.terms;
  const [fullName, setFullName] = useState<string>("");
  const userFullName = `${user.first_name} ${user.last_name}`;

  const goToRegistration = (signed: boolean) => {
    navigate("/accounts/register", {
      state: {
        user,
        signed,
      },
    });
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Header darkTheme />
        </Col>
      </Row>
      <Row>
        <RelativeCol>
          <LeftCol>
            <div className='mb-1'>
              <a
                href='#'
                onClick={(e) => {
                  e.preventDefault();
                  goToRegistration(false);
                }}
              >
                &lt; BACK
              </a>
            </div>
            <TermsScrollBar className='mb-3'>
              <div
                className='repAgreement'
                dangerouslySetInnerHTML={{ __html: terms }}
              ></div>
            </TermsScrollBar>
            <Container fluid>
              <Row>
                <Col>
                  <p>
                    By clicking “SUBMIT” I agree that my typed-in signature will
                    be the electronic representation of my signature (ie.
                    “e-signature”) for the purposes of entering into this
                    Agreement.
                  </p>
                  <p>
                    Sign by typing your full name exactly as it appears: “
                    {`${user.first_name} ${user.last_name}`}”
                  </p>
                  <div className='sidebarStyle'>
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        goToRegistration(true);
                      }}
                    >
                      <InputGroup>
                        <Form.Control
                          style={{ width: "auto" }}
                          onChange={(e) => setFullName(e.target.value)}
                          type='text'
                          required
                        />
                        <Button
                          style={{ width: "auto" }}
                          variant='primary'
                          type='submit'
                          disabled={
                            userFullName.toLowerCase() !==
                            fullName.toLowerCase()
                          }
                        >
                          SUBMIT
                        </Button>
                      </InputGroup>
                    </Form>
                  </div>
                </Col>
              </Row>
            </Container>
          </LeftCol>
          <Separator />
        </RelativeCol>
        <RightCol>
          <p>
            <b>Hi!</b> Thanks for installing SELF Realty.
          </p>
          <p>
            In order for us to provide you with our unbiased opinions on real
            estate matters, we are required to have an agency agreement in place. This agreement is non-exclusive, limited in scope, and you can terminate at any time for any reason.
          </p>
          <p>SELF Realty does not charge commissions. Our fees are clearly marked and paid as the services are used. The rent estimation service is free.
          </p>
          <br />
          <img src='/images/estimate_graphic.png' alt='Self' />
        </RightCol>
      </Row>
    </Container>
  );
};
