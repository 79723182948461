import { useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { useApi } from "../../../../api/useApi";
import styled from "styled-components";

export interface Item {
  v1PropertyId: string;
  address: string;
}

const SearchWrapper = styled.div`
  width: 450px;

  .form-control {
    border-radius: 30px;
  }

  .dropdown-menu {
    background: #fff !important;
  }
  .dropdown-item,
  .dropdown-item:hover {
    color: #000 !important;
  }
`;
interface Props {
  value?: string;
  onChange: (propertyId: string, address: string) => void;
}

export const SearchTypeAhead = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<Item[]>([]);

  const apiContext = useApi();

  const handleSearch = (query: string) => {
    setIsLoading(true);

    apiContext?.searchMetadata(query).then((items: Item[]) => {
      setOptions(items);
      setIsLoading(false);
    });
  };

  const handlePropertySelection = async (items: any) => {
    if (items.length > 0) {
      setIsLoading(true);
      props.onChange(items[0]["v1PropertyId"], items[0]["address"]);
      await apiContext?.updateSearchHistory(
        items[0]["address"],
        items[0]["v1PropertyId"]
      );
      setIsLoading(false);
    }
  };

  const getUserHistory = async () => {
    const results = await apiContext?.getSearchHistory();
    setOptions(results);
  };

  const filterBy = () => true;

  return (
    <SearchWrapper>
      <AsyncTypeahead
        minLength={0}
        defaultInputValue={props.value}
        filterBy={filterBy}
        id='typeAhead'
        placeholder='Try entering an address'
        isLoading={isLoading}
        //@ts-ignore
        labelKey={(option) => `${option.address}`}
        onSearch={handleSearch}
        onFocus={getUserHistory}
        options={options}
        onChange={handlePropertySelection}
      />
    </SearchWrapper>
  );
};
