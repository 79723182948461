import { Col, Row, Image, Container } from "react-bootstrap";
import { styled } from "styled-components";

const ActiveModulesWrapper = styled.div`
  border: 2px solid rgba(106, 118, 160, 0.23);
  border-radius: 8px;
  padding: 10px 0px 16px;
  color: #fff;

  ul {
    padding: 0;
    margin: 0;
    font-size: 12px;
  }
  li {
    list-style: none;
    padding-left: 15px;
  }
`;

const ModuleName = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  text-transform: uppercase;
`;

const ModuleCopyright = styled.div`
  font-size: 12px;
  opacity: 0.5;
`;
const LeftCol = styled(Col)`
  flex: 0 0 144px;
`;
const RightCol = styled(Col)`
  flex: 0 0 158px;
  align-content: space-evenly;
  padding-left: 0px;
`;

interface Props extends React.HTMLAttributes<HTMLDivElement>, React.PropsWithChildren {
  name: string;
  imageName: string;
}



export const ModulePreview = (props: Props) => {
  return (
    <ActiveModulesWrapper className={props.className}>
      <Container>
        <Row>
          <LeftCol>
            <Image
              rounded
              src={`/images/module_icons/${props.imageName}`}
              width={120}
            />
          </LeftCol>
          <RightCol>
            <ModuleName>{props.name}</ModuleName>
            <ModuleCopyright>by SELF Realty</ModuleCopyright>
          </RightCol>
        </Row>
        {props.children && <Row><Col>{props.children}</Col></Row>}
      </Container>
    </ActiveModulesWrapper>
  );
};
