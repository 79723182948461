import React from "react";
import { Col } from "react-bootstrap";

export const Sidebar = (props: React.PropsWithChildren) => {
  return (
    <Col style={{ flex: "0 0 330px" }} className='sidebarStyle'>
      {props.children}
    </Col>
  );
};
