import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

import {
  AddComparable,
  ContactDetails,
  ExternalLinks,
  ListingDetailsExpanded,
  RentComparables,
  RentEstimations,
} from "../../components/listing";
import { MapContainer } from "../../components/listing/Map";
import {
  ListingCompactView,
  Estimation,
  Listing,
} from "../../../api/interfaces/listing";
import { ModulePreview } from "../../widgets";
import { DossierWidget, ProformaWidget } from "./components";
import { Sidebar } from "../../components/layout";
import { SubjectListing } from "../../components/SubjectListing";

const Footer = () => {
  return (
    <div className='footer'>
      <div>
        <a href='https://www.selfrealty.com/dmca' target='_blank'>
          DMCA
        </a>
        {" "}·{" "}
        <a href='https://www.selfrealty.com/privacy-policy' target='_blank'>
          PRIVACY POLICY
        </a>
        {" "}·{" "}
        <a
          href='https://capitol.texas.gov/tlodocs/82R/billtext/html/HB02973F.htm'
          target='_blank'>
          SLAPP
        </a>
        {" "}·{" "}
        <a href='https://www.selfrealty.com/contact' target='_blank'>
          CONTACT
        </a>
      </div>
      <div>COPYRIGHT © SELF Realty, LLC</div>
    </div>
  );
};

interface Props {
  comparables: ListingCompactView[];
  estimation: Estimation | null;
  listing: Listing;
  paidUser: boolean;
  loading: boolean;
  handleComparablesUpdate: (comparablesIds: string[]) => Promise<void>;
}

export const Rentomatic = (props: Props) => {
  const [selectedListing, setSelectedListing] = useState<string | null>(null);

  return (
    <>
      <Row>
        <Col>
          <SubjectListing />
          <div className='expandedView mainCol'>
            <div className='mb-3'>
              <ListingDetailsExpanded listing={props.listing} />
            </div>
            <div className='mb-3'>
              <MapContainer
                selectedMarker={selectedListing}
                onMarkerSelect={(listingKey) => setSelectedListing(listingKey)}
                comparables={props.comparables}
                startingLat={props.listing.latitude}
                startingLng={props.listing.longitude}
                startingKey={props.listing.listing_key}
              />
            </div>
            {props.estimation && (
              <div className='panel mb-3'>
                <RentEstimations
                  withEvaluations
                  estimation={props.estimation}
                  comparables={props.comparables}
                />
              </div>
            )}
            <div className={props.comparables.length > 0 ? "panel" : ""}>
              <AddComparable
                onAdd={props.handleComparablesUpdate}
                comparables={props.comparables}
              />
              <RentComparables
                editable
                selected={selectedListing}
                size={2}
                updateComparables={props.handleComparablesUpdate}
                comparables={props.comparables}
                loading={props.loading}
              />
            </div>
          </div>
        </Col>
        <Sidebar>
          {/* <ModulePreview
            name='RENT-O-MATIC'
            imageName='rent-o-matic.png'
            className='mb-4'
          /> */}
          <h2>Listing Contact Info</h2>
          <div className='mb-5'>
            <ContactDetails listing={props.listing} />
            <ExternalLinks enableMls listing={props.listing} />
          </div>
          {props.listing.property_type !== "Residential Lease" && (
            <>
              <h2>Next steps</h2>
              <ProformaWidget
                className="mb-3"
                enabled={props.paidUser}
                estimatedReturn={props.estimation?.return_prediction}
                listingKey={props.listing.listing_key}
              />
              <DossierWidget
                className="mb-3"
                enabled={props.paidUser}
                estimatedReturn={props.estimation?.return_prediction}
                listingKey={props.listing.listing_key}
              />
            </>
          )}
          <div className='fixedFooter'>
            <Footer />
          </div>
        </Sidebar>
      </Row>
    </>
  );
};
