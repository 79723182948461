import { APIProvider, Map } from "@vis.gl/react-google-maps";
import type {} from "@vis.gl/react-google-maps";
import { MapMarker, MapMarkerType } from "../layout/MapMarker";
import { ListingCompactView } from "../../../api/interfaces/listing";
import styled from "styled-components";

interface Props {
  startingLat?: number;
  startingLng?: number;
  startingKey?: string;
  comparables: ListingCompactView[];
  selectedMarker?: string | null;
  onMarkerSelect: (listingKey: string) => void;
}

const MapWrapper = styled.div`
  margin: 0px 0px 0px 0px;
`;

export function MapContainer(props: Props) {
  const rad2degr = (rad: number): number => {
    return (rad * 180) / Math.PI;
  };
  const degr2rad = (degr: number): number => {
    return (degr * Math.PI) / 180;
  };
  const getLatLngCenter = (latLngInDegr: number[][]): number[] => {
    if (latLngInDegr.length === 0) {
      return [0, 0];
    }

    var LATIDX = 0;
    var LNGIDX = 1;
    var sumX = 0;
    var sumY = 0;
    var sumZ = 0;

    for (var i = 0; i < latLngInDegr.length; i++) {
      var lat = degr2rad(latLngInDegr[i][LATIDX]);
      var lng = degr2rad(latLngInDegr[i][LNGIDX]);
      // sum of cartesian coordinates
      sumX += Math.cos(lat) * Math.cos(lng);
      sumY += Math.cos(lat) * Math.sin(lng);
      sumZ += Math.sin(lat);
    }

    var avgX = sumX / latLngInDegr.length;
    var avgY = sumY / latLngInDegr.length;
    var avgZ = sumZ / latLngInDegr.length;

    // convert average x, y, z coordinate to latitude and longtitude
    var lng = Math.atan2(avgY, avgX);
    var hyp = Math.sqrt(avgX * avgX + avgY * avgY);
    var lat = Math.atan2(avgZ, hyp);
    return [rad2degr(lat), rad2degr(lng)];
  };

  const markers = [
    ...props.comparables.map((listing) => [
      listing.latitude,
      listing.longitude,
    ]),
  ];
  if (props.startingLat && props.startingLng) {
    markers.push([props.startingLat, props.startingLng]);
  }

  const position = getLatLngCenter(markers);

  const styles = [
    {
      stylers: [
        {
          hue: "#cccccc",
        },
        {
          saturation: -100,
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          lightness: 100,
        },
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ];

  return (
    <MapWrapper>
      <APIProvider apiKey={"AIzaSyAafNtCtx8Q7fh6bbFQBi4bq4Br-mzVY9g"}>
        <Map
          style={{
            height: 200,
            borderRadius: 5,
          }}
          styles={styles}
          mapTypeControl={false}
          defaultCenter={{ lat: position[0], lng: position[1] }}
          defaultZoom={12}
          mapTypeId={"hybrid"}
          streetViewControl={false}
        >
          {props.comparables
            .filter((listing) => listing.enabled)
            .map((listing) => (
              <MapMarker
              type={MapMarkerType.Comparables}
                selected={props.selectedMarker === listing.listing_key}
                onClick={props.onMarkerSelect}
                key={listing.listing_key}
                lat={listing.latitude}
                lng={listing.longitude}
                markerKey={listing.listing_key}
              />
            ))}
          {props.startingLat && props.startingLng && props.startingKey && (
            <MapMarker
              type={MapMarkerType.Main}
              onClick={props.onMarkerSelect}
              key={props.startingKey}
              lat={props.startingLat}
              lng={props.startingLng}
              markerKey={props.startingKey}
              main
            />
          )}
        </Map>
      </APIProvider>
    </MapWrapper>
  );
}
