import { useOutletContext } from "react-router-dom";

export const RegionNotSupported = () => {
  //@ts-ignore
  const { logCustomError } = useOutletContext();
  logCustomError("Region not supported");

  return (
    <>
      <div style={{ fontSize: 16 }}>
        <p>Insufficient data available to analyze this property.</p>
      </div>

      <hr />
      <p>
        <a href='https://www.selfrealty.com/start' target='_blanks'>
          <b>Quick Start</b>
        </a>
      </p>
    </>
  );
};
