import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

import {
  AddComparable,
  ContactDetails,
  RentComparables,
  RentEstimations,
} from "../../components/listing";
import { MapContainer } from "../../components/listing/Map";
import {
  ListingCompactView,
  Estimation,
} from "../../../api/interfaces/listing";
import { ModulePreview } from "../../widgets";
import { ListingDetailsCustom } from "../../components/listing/ListingDetailsCustom";
import { Sidebar } from "../../components/layout";
import { SubjectListing } from "../../components/SubjectListing";


const Footer = () => {
  return (
    <div className='footer'>
      <div>
        <a href='https://www.selfrealty.com/dmca' target='_blank'>
          DMCA
        </a>
        ·
        <a href='https://www.selfrealty.com/privacy-policy' target='_blank'>
          PRIVACY POLICY
        </a>
        ·
        <a
          href='https://capitol.texas.gov/tlodocs/82R/billtext/html/HB02973F.htm'
          target='_blank'
        >
          SLAPP
        </a>{" "}
        ·
        <a href='https://www.selfrealty.com/contact' target='_blank'>
          CONTACT
        </a>
      </div>
      <div>COPYRIGHT © 2023-2024 SELF Realty, LLC</div>
    </div>
  );
};

interface Props {
  listing: Record<string, any>;
  comparables: ListingCompactView[];
  estimation: Estimation | null;
  loading: boolean;
  handleComparablesUpdate: (comparablesIds: string[]) => Promise<void>;
}

export const RentomaticCustom = (props: Props) => {
  const [selectedListing, setSelectedListing] = useState<string | null>(null);


  return (
    <>
      <Row>
        <Col>
        <SubjectListing />
          <div className='expandedView mainCol'>
            <div className='mb-3'>
              <ListingDetailsCustom
                property_type={props.listing["property_type"]}
                postal_code={props.listing["postal_code"]}
                city={props.listing["city"]}
                address={props.listing["complete_address"]}
                bathrooms={props.listing["bathrooms"]}
                bedrooms={props.listing["bedrooms"]}
                living_area={props.listing["square_feet"]}
              />
            </div>
            <div className='mb-3'>
              <MapContainer
                selectedMarker={selectedListing}
                onMarkerSelect={(listingKey) => setSelectedListing(listingKey)}
                comparables={props.comparables}
                startingLat={props.listing["lat"]}
                startingLng={props.listing["lng"]}
                startingKey={"custom"}
              />
            </div>
            {props.estimation && (
              <div className='panel mb-3'>
                <RentEstimations
                  withEvaluations
                  estimation={props.estimation}
                  comparables={props.comparables}
                />
              </div>
            )}
            <div className={props.comparables.length > 0 ? "panel" : ""}>
              <AddComparable
                onAdd={props.handleComparablesUpdate}
                comparables={props.comparables}
              />
              <RentComparables
                editable
                selected={selectedListing}
                size={2}
                updateComparables={props.handleComparablesUpdate}
                comparables={props.comparables}
                loading={props.loading}
              />
            </div>
          </div>
        </Col>
        <Sidebar>
          <ModulePreview
            name='RENT-O-MATIC'
            imageName='rent-o-matic.png'
            className='mb-4'
          />

          <h2>Contact info</h2>
          <div className='mb-5'>
            <ContactDetails />
          </div>

          <div className='fixedFooter'>
            <Footer />
          </div>
        </Sidebar>
      </Row>
    </>
  );
};
