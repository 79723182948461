import { Col, Image, Row } from "react-bootstrap";
import { Share } from "react-feather";
import styled from "styled-components";

const Wrapper = styled.div`
  background: #42465d;
  border-radius: 8px;
  border-color: #c8c8ec;
  border-style: solid;
  padding: 8px;
`;

const ShareLink = styled.a`
  color: #fff;
  display: block;
  position: absolute;
  top: -5px;
  right: 10px;
  cursor: pointer;
  transform: rotate(45deg);

  svg {
    width: 17px;
  }
`;

const ImageCol = styled(Col)`
  flex: 0 0 80px;
  padding-right: 0;
`;

const RightCol = styled(Col)`
  align-content: space-evenly;
  padding-left: 0;
`;

const Content = styled.div`
  font-weight: bold;
  font-size: 23px;
  line-height: 26px;
  color: white;
  text-align: center;
  text-transform: none;

  & div:nth-child(2n) {
    font-size: 12px;
    font-weight: 100;
    line-height: 12px;
    margin-top: 5px;
  }
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  image: string;
  title: string;
  subtitle: string;
  redirect?: () => void;
}

export const Widget = (props: Props) => {
  return (
    <Wrapper className={props.className}>
      <Row>
        <ImageCol>
          <Image
            rounded
            src={props.image}
            width={80}
          />
        </ImageCol>
        <RightCol>
          {props.redirect && <ShareLink onClick={props.redirect}><Share/></ShareLink>}
          <Content className="mb-2">
            <div>{props.title}</div>
            <div>{props.subtitle}</div>
            <div className="clear"></div>
          </Content>
        </RightCol>
      </Row>
    </Wrapper>
  );
};
