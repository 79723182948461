import { useEffect, useState } from "react";

import { useApi } from "../../../api/useApi";
import { Proforma as ProformaType } from "../../../api/interfaces/proforma";

import { useNavigate, useParams } from "react-router-dom";
import hash from "object-hash";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { Proforma } from "./Proforma";
import { Listing } from "../../../api/interfaces/listing";
import { useListing } from "../../hooks/useListing";

export const ProformaWrapper = () => {
  const { listingString } = useParams();
  const navigate = useNavigate();
  const apiContext = useApi();
  const listingContext = useListing();
  const [listing, setListing] = useState<Listing | null>(null);
  const [proforma, setProforma] = useState<ProformaType | null>(null);
  const [hashKey, setHash] = useState<string>("");
  const [initialLoading, setInitialLoading] = useState<boolean>(true);


  const listingKey = listingString ?? listingContext?.listingKey

  const handleLoadProforma = async (): Promise<void> => {
    const data = await apiContext?.getProforma(listingKey!);
    if (data) {
      setProforma(data.proforma);
      setListing(data.listing);
    }
  };

  const onSave = async (proforma: ProformaType): Promise<void> => {
    setInitialLoading(true);
    const newProforma = await apiContext?.saveProforma(listingKey!, proforma);
    if (newProforma) {
      setProforma(newProforma);
    }
    setInitialLoading(false);
  };

  const onDelete = async (): Promise<void> => {
    setInitialLoading(true);
    await apiContext?.deleteProforma(listingKey!);
    await handleLoadProforma();
    setInitialLoading(false);
  };

  const onReset = () => {
    setProforma(proforma);
    setHash(
      hash({
        time: new Date().valueOf(),
      })
    );
  };

  useEffect(() => {
    if (listingKey) {
      setInitialLoading(true);
      handleLoadProforma()
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setInitialLoading(false);
        });
    } else {
      setInitialLoading(false);
    }
  }, [listingKey]);


  if (initialLoading || !proforma) {
    return <LoadingIndicator />;
  }

  if (listingKey === null) {
    navigate('/dashboard')
  }
  return (
    <>
      
      <Proforma
        key={hashKey}
        proforma={proforma}
        listing={listing}
        onSave={onSave}
        onReset={onReset}
        onDelete={onDelete}
      />
    </>
  );
};
