import { useOutletContext } from "react-router-dom";

export const Apartments = () => {
  //@ts-ignore
  const { logCustomError } = useOutletContext();
  logCustomError("Search page not supported");

  return (
    <>
      <div style={{ fontSize: 16 }}>
        <p>
          You’re looking at an apartment community page, not a specific listing
          so we cannot provide analysis.
        </p>
      </div>

      <hr />
      <p>
        <a href='https://www.selfrealty.com/start' target='_blanks'>
          <b>Quick Start</b>
        </a>
      </p>
    </>
  );
};
