import { useNavigate, useParams } from "react-router-dom";
import { useApi } from "../../../api/useApi";
import {
  Estimation,
  Listing,
  ListingCompactView,
} from "../../../api/interfaces/listing";
import { useEffect, useState } from "react";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { Rentomatic } from "./Rentomatic";
import { RentomaticCustom } from "./RentomaticCustom";
import { useListing } from "../../hooks/useListing";

export const RentomaticWrapper = () => {
  const apiContext = useApi();
  const navigate = useNavigate();
  const listingContext = useListing();
  const { listingString } = useParams();
  const [listing, setListing] = useState<Listing | null>(null);
  const [paidUser, setPaidUser] = useState<boolean>(false);
  const [comparables, setComparables] = useState<ListingCompactView[]>([]);
  const [estimation, setEstimation] = useState<Estimation | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  let listingKey = listingString ?? listingContext?.listingKey

  const mlsListing = listingKey?.length && listingKey?.length <= 32;
  if (!mlsListing && listingKey) {
    try {
      listingKey = decodeURIComponent(listingKey)
    } catch (error) {
      
    }
  }


  const handleLoadUser = async (): Promise<void> => {
    const data = await apiContext?.getUserData();
    setPaidUser(data?.has_subscription || false);
  };

  const handleLoadComparablesCustom = async (
    optionalComparables: string[]
  ): Promise<void> => {
    try {
      const data = await apiContext?.getCustomRentomatic(
        JSON.parse(listingKey!),
        optionalComparables
      );
      if (data) {
        if (data.comparables.length > 0) {
          setComparables(data.comparables);
          setEstimation(data.estimation);
        } else {
          navigate("/not_found/not_supported_region");
        }
      }
    } catch (error: any) {
      if (error.property_type && error.property_type.length > 0) {
        navigate("/not_found/not_supported_type", {
          state: {
            propertyType: error.property_type[0],
          },
        });
      }
    }
  };

  const handleLoadComparablesMLS = async (): Promise<void> => {
    const data = await apiContext?.getRentomatic(listingKey!);
    if (data) {
      setComparables(data.comparables);
      setEstimation(data.estimation);
    }
  };

  const handleLoadListing = async (): Promise<void> => {
    const data = await apiContext?.getListing(listingKey!);
    setListing(data);
  };

  const handleComparablesUpdate = async (
    comparablesIds: string[]
  ): Promise<void> => {
    setLoading(true);
    try {
      if (mlsListing) {
        await apiContext?.updateRentomatic(listingKey!, comparablesIds);
        await handleLoadComparablesMLS();
      } else {
        await handleLoadComparablesCustom(comparablesIds);
      }
    } catch (error) {
      throw new Error();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleLoadUser();
  }, []);

  const handleInitialLoad = async () => {
    const tasks = [
      mlsListing ? handleLoadComparablesMLS() : handleLoadComparablesCustom([]),
    ];
    if (mlsListing) {
      tasks.push(handleLoadListing());
    }
    return Promise.all(tasks);
  };

  useEffect(() => {
    if (listingKey) {
      setInitialLoading(true);
      handleInitialLoad()
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
          setInitialLoading(false);
        });
    } else {
      setInitialLoading(false);
    }
  }, [listingKey]);

  if (listingKey === null) {
    navigate('/dashboard')
  }

  if (initialLoading || (mlsListing && !listing)) {
    return <LoadingIndicator />;
  }
  if (mlsListing && listing) {
    return (
      <Rentomatic
        loading={loading}
        listing={listing}
        handleComparablesUpdate={handleComparablesUpdate}
        paidUser={paidUser}
        comparables={comparables}
        estimation={estimation}
      />
    );
  }
  if (listingKey) {
    const listingObj = JSON.parse(listingKey);
    return (
      <RentomaticCustom
        listing={listingObj}
        loading={loading}
        comparables={comparables}
        estimation={estimation}
        handleComparablesUpdate={handleComparablesUpdate}
      />
    );
  }
  return null;
};
