import { Col, Image, Row } from "react-bootstrap";
import { PlusCircle } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Module } from "./module.type";
import { useModules } from "./module.hook";
import { SubjectListing, LoadingIndicator } from "../../components";

const ModuleBoxRow = styled(Row)`
  border-bottom: 2px solid #d4d4f9;
  padding-bottom: 15px;
  padding-top: 15px;
  margin-left: 0px;
  margin-right: 0px;

  &:last-child {
    border: none;
  }
`;

const ModuleImageCol = styled(Col)`
  flex: 0 0 160px;
  padding-left: 0px;
`;
const ModuleDescriptionCol = styled(Col)`
  padding-right: 0px;
  font-size: 14px;
  color: #333;
  font-weight: 600;
`;

const ModuleStatus = styled.div`
  font-size: 13px;
  line-height: 20px;

  svg {
    color: #4286f7;
    width: 15px;
    height: 15px;
    margin-right: 3px;
    margin-top: -3px;
  }
`;

const OpenModuleBtn = styled.button`
  line-height: 17px;
  height: 17px;
  box-sizing: border-box !important;
  width: 40px;
  box-sizing: initial;
  border-radius: 2px;
  background: #4286f7;
  border: none;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
`;

const ModulesSubTitle = styled.div`
  color: #8c8c8c;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding-top: 10px;

  a {
    color: #8c8c8c;
  }
`;

const ModuleBox = (props: Module) => {
  const navigate = useNavigate();
  const handleModuleOpen = async (selectedModule: Module) => {
    if (!selectedModule.added) {
      navigate("/dashboard");
      return;
    }
    navigate(selectedModule.url);
  };
  return (
    <ModuleBoxRow key={props.moduleId}>
      <ModuleImageCol>
        <Image width={150} rounded src={props.imagePath} />
      </ModuleImageCol>
      <ModuleDescriptionCol>
        <ModuleStatus className='mb-2'>
          {props.added && (
            <OpenModuleBtn onClick={() => handleModuleOpen(props)}>
              OPEN
            </OpenModuleBtn>
          )}
          {!props.added && (
            <>
              <Link to='/dashboard'>
                <PlusCircle />
              </Link>
              {props.price}
            </>
          )}
        </ModuleStatus>
        <p className='mb-2'>{props.title}</p>
        <p>{props.description}</p>
      </ModuleDescriptionCol>
    </ModuleBoxRow>
  );
};

export function Dashboard() {
  const [modules, loading] = useModules();
  if (loading) {
    return <LoadingIndicator />;
  }
  return (
    <>
      <SubjectListing />
      <div className='expandedView mainCol'>
        <ModulesSubTitle>Available Services from SELF Realty</ModulesSubTitle>
        {modules.map((moduleProps, index) => (
          <ModuleBox key={moduleProps.moduleId} {...moduleProps} />
        ))}
      </div>
    </>
  );
}
