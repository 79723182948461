import { Form, InputGroup, Table } from "react-bootstrap";
import { styled } from "styled-components";
import { formatPercent } from "./PriceInput";

interface Props {
  rentEstimate: number;
  downPayment: number;
  offerPrice: number;
  closingCosts: number;

  downPaymentRate: number;
  setProformaDownPayment: (val: number) => void;

  closingCostsRate: number;
  setProformaClosingsCosts: (val: number) => void;

  cleanAndRepair: number;
  setCleanAndRepair: (val: number) => void;

  loanTerm: number;
  setLoanTerm: (val: number) => void;

  interestRate: number;
  setInterestRate: (val: number) => void;

  vacancy: number;
  setVacancy: (val: number) => void;

  providerEstimatedTaxes: number;
  userTaxRate: number;
  setTaxUserEstimate: (val: number) => void;

  enableTaxUserEstimate: boolean;
  setEnableTaxUserEstimate: (val: boolean) => void;

  insurance: number;
  setInsurance: (val: number) => void;

  repairAndMaintenanceRate: number;
  setProformaRepairAndMaintenance: (val: number) => void;

  propertyManagementRate: number;
  setPropertyManagement: (val: number) => void;

  leaseListingFee: number;
  setListingFees: (val: number) => void;
}

export const AssumptionsTable = styled(Table)`
  td,
  th {
    font-size: 14px;
    color: #d4d4f9;
    border: none;
    background: none;
    padding-top: 3px;
    padding-bottom: 2px;
    padding-right: 0px;
  }

  th {
    padding: 0;
    font-weight: 600;
    text-align: right;
  }
  td {
    width: 30%;
  }
  td:first-child {
    padding-left: 10px;
    width: 70%;
  }
  th:first-child {
    text-align: left;
  }
  .form-control, .form-select {
    padding: 0px 7px 0px 0px;
    height: 22px;
    line-height: 22px;
    color: #333333;
    font-size: 14px;
    background: #fff;
    text-align: right;
    border: 1px solid #c8c8ec;
    border-radius: 2px;
  }
  .input-group-text {
    color: #d4d4f9;
    background: none;
    padding: 0;
    width: 24px;
    justify-content: center;
    line-height: 20px;
    border-radius: 2px;
    border: 1px solid #c8c8ec;
    border-right-width: 2px;
  }
`;

const formatValue = (val: string): number => {
  const value = parseFloat(val);
  if (Number.isNaN(value)) return 0;
  return value;
};

export const AssumptionsEditor = (props: Props) => {
  return (
    <>
    <br />
    <h2>Assumptions</h2>
      <AssumptionsTable>
        <thead>
          <tr>
            <th colSpan={2}>Transaction Costs</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Closing costs</td>
            <td>
              <InputGroup style={{ width: 90 }}>
                <InputGroup.Text>%</InputGroup.Text>
                <Form.Control
                  value={formatPercent(props.closingCostsRate)}
                  type='number'
                  min={0}
                  max={100}
                  step={0.1}
                  onChange={(e) =>
                    props.setProformaClosingsCosts(
                      (props.offerPrice / 100) * formatValue(e.target.value)
                    )
                  }
                />
              </InputGroup>
            </td>
          </tr>
          <tr>
            <td>Clean & Repair</td>
            <td>
              <InputGroup style={{ width: 90 }}>
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  value={Number(props.cleanAndRepair).toString()}
                  min={0}
                  type='number'
                  onChange={(e) =>
                    props.setCleanAndRepair(formatValue(e.target.value))
                  }
                />
              </InputGroup>
            </td>
          </tr>
        </tbody>
      </AssumptionsTable>
      <AssumptionsTable>
        <thead>
          <tr>
            <th colSpan={2}>Financing & Carrying Costs</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Down Payment</td>
            <td>
              <InputGroup style={{ width: 90 }}>
                <InputGroup.Text>%</InputGroup.Text>
                <Form.Control
                  value={formatPercent(props.downPaymentRate)}
                  type='number'
                  min={0}
                  max={100}
                  step={0.1}
                  onChange={(e) =>
                    props.setProformaDownPayment(
                      Math.round(
                        props.offerPrice * (formatValue(e.target.value) / 100)
                      )
                    )
                  }
                />
              </InputGroup>
            </td>
          </tr>
          <tr>
            <td>Term</td>
            <td>
              <Form.Control
                style={{ width: 67 }}
                value={Number(props.loanTerm).toString()}
                type='number'
                min={0}
                max={100}
                step={0.1}
                className='fright'
                onChange={(e) => props.setLoanTerm(formatValue(e.target.value))}
              />
            </td>
          </tr>
          <tr>
            <td>Interest Rate</td>
            <td>
              <InputGroup style={{ width: 90 }}>
                <InputGroup.Text>%</InputGroup.Text>
                <Form.Control
                  value={Number(formatPercent(props.interestRate)).toString()}
                  type='number'
                  min={0}
                  max={100}
                  step={0.01}
                  onChange={(e) =>
                    props.setInterestRate(formatValue(e.target.value))
                  }
                />
              </InputGroup>
            </td>
          </tr>
        </tbody>
      </AssumptionsTable>
      <AssumptionsTable>
        <thead>
          <tr>
            <th colSpan={2}>Operating Costs</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Vacancy</td>
            <td>
              <Form.Control
                style={{ width: 67 }}
                value={Number(formatPercent(props.vacancy)).toString()}
                min={0}
                type='number'
                className='fright'
                onChange={(e) => props.setVacancy(formatValue(e.target.value))}
              />
            </td>
          </tr>
          <tr>
            <td>Repairs & Maintanence</td>
            <td>
              <InputGroup style={{ width: 90 }}>
                <InputGroup.Text>%</InputGroup.Text>
                <Form.Control
                  value={formatPercent(props.repairAndMaintenanceRate)}
                  type='number'
                  min={0}
                  max={100}
                  step={0.1}
                  onChange={(e) =>
                    props.setProformaRepairAndMaintenance(
                      props.rentEstimate * (formatValue(e.target.value) / 100)
                    )
                  }
                />
              </InputGroup>
            </td>
          </tr>
          <tr>
            <td>Property Management</td>
            <td>
              <InputGroup style={{ width: 90 }}>
                <InputGroup.Text>%</InputGroup.Text>
                <Form.Control
                  value={formatPercent(props.propertyManagementRate)}
                  type='number'
                  min={0}
                  step={0.1}
                  onChange={(e) =>
                    props.setPropertyManagement(
                      props.rentEstimate * (formatValue(e.target.value) / 100)
                    )
                  }
                />
              </InputGroup>
            </td>
          </tr>
          <tr>
            <td>Lease Listing</td>
            <td>
              <InputGroup style={{ width: 90 }}>
                <InputGroup.Text>%</InputGroup.Text>
                <Form.Control
                  value={formatPercent(props.leaseListingFee)}
                  type='number'
                  min={0}
                  step={0.1}
                  onChange={(e) =>
                    props.setListingFees(
                      (props.rentEstimate *
                        (formatValue(e.target.value) / 100)) /
                        12
                    )
                  }
                />
              </InputGroup>
            </td>
          </tr>
          <tr>
            <td>Insurance</td>
            <td>
              <InputGroup style={{ width: 90 }}>
                <InputGroup.Text>%</InputGroup.Text>
                <Form.Control
                  value={formatPercent(props.insurance)}
                  type='number'
                  min={0}
                  max={100}
                  step={0.1}
                  onChange={(e) =>
                    props.setInsurance(
                      (props.offerPrice * (formatValue(e.target.value) / 100)) /
                        12
                    )
                  }
                />
              </InputGroup>
            </td>
          </tr>
          <tr>
            <td>Property Tax Source</td>
            <td>
              <Form.Select value={props.enableTaxUserEstimate ? 'user' : 'mls'} onChange={(e) => {
                const enableTaxUserEstimate = e.target.value === 'user'
                props.setEnableTaxUserEstimate(enableTaxUserEstimate)
                props.setTaxUserEstimate((enableTaxUserEstimate ? (props.offerPrice * props.userTaxRate / 100) : props.providerEstimatedTaxes) / 12)
              }}>
                <option value='mls'>MLS</option>
                <option value='user'>User</option>
              </Form.Select>
            </td>
          </tr>
          <tr>
            <td>Property Tax User Estimate</td>
            <td>
              <InputGroup style={{ width: 90 }}>
                <InputGroup.Text>%</InputGroup.Text>
                <Form.Control
                  disabled={!props.enableTaxUserEstimate}
                  value={formatPercent(props.userTaxRate)}
                  type='number'
                  min={0}
                  max={100}
                  step={0.1}
                  onChange={(e) => {
                    if (props.enableTaxUserEstimate) {
                      props.setTaxUserEstimate(
                        (props.offerPrice * formatValue(e.target.value)) /
                          100 /
                          12
                      );
                    }
                  }}
                />
              </InputGroup>
            </td>
          </tr>
        </tbody>
      </AssumptionsTable>
    </>
  );
};
