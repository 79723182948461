import styled from "styled-components";
import { Listing } from "../../../api/interfaces/listing";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useState } from "react";
import { useApi } from "../../../api/useApi";
import { InfoTooltip } from "../InfoTooltip";
import { FavoriteIcon } from "./FavoriteIcon";

interface Props {
  listing: Listing;
}

const Wrapper = styled.div`
  background: #e4e4e5;
  border-radius: 8px;
  height: 90px;
  overflow: hidden;
  position: relative;
`;

const Address = styled.div`
  color: #333333;
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px;
`;
const MiddleRow = styled.div`
  color: #000000;
  font-size: 14px;

  span {
    float: right;
  }
`;

const ListingProps = styled.div`
  margin-top: 3px;
  font-size: 12px;
  span {
    color: #656f78;
    border-left: 1px solid #656f78;
    padding: 0px 5px;
  }
  span:first-child {
    padding-left: 0px;
    border: none;
  }
  span.last {
    float: right;
    padding: 0px;
    border: none;
  }
`;

const ImageWrapper = styled(Image)`
  width: 125px;
  height: 90px;
  object-fit: cover;
`;

const FavoriteWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`;

const ListingInfo = styled.div`
  margin: 20px 0px;
  color: #625f57;
`;

const ListingInfoCol = styled(Col)`
  border-right: 1px solid #dcdcea;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;

  &.no-border {
    border: none;
  }
`;

const ListingInfoTitle = styled.div`
  color: #8c8c8c;
  font-weight: lighter;
  text-transform: uppercase;
`;

export function ListingDetailsExpanded({ listing }: Props) {
  const apiContext = useApi();
  const [loading, setLoading] = useState<boolean>(false);
  const [favorite, setFavorite] = useState<boolean>(listing.favorite);

  const handleAddToFavorites = async (listingKey: string): Promise<void> => {
    setLoading(true);
    await apiContext?.addToFavorites(listingKey);
    setFavorite(true);
    setLoading(false);
  };

  const handleRemoveFromFavorites = async (
    listingKey: string
  ): Promise<void> => {
    setLoading(true);
    await apiContext?.removeFromFavorites(listingKey);
    setFavorite(false);
    setLoading(false);
  };

  const property = listing;

  return (
    <>
      <Wrapper>
        <Container>
          <Row>
            <Col style={{ flex: "0 0 125px", paddingLeft: 0 }}>
              <ImageWrapper
                src={
                  property.media_url === ""
                    ? "/images/not-found.jpeg"
                    : property.media_url
                }
              />
            </Col>
            <Col>
              <Address>{property.full_address}</Address>
              <MiddleRow>
                {property.city}{", "}{property.state}{" "}{property.postal_code}
                <span>
                  <InfoTooltip
                    placement='left'
                    label='Last List Price'
                  >{`$${property.list_price.toLocaleString()}`}</InfoTooltip>
                </span>
              </MiddleRow>
              <ListingProps>
                <span>{property.bedrooms_total || 0} bed</span>
                <span>{property.bathrooms_total_decimal || 0} bath</span>
                <span>{property.living_area.toLocaleString() || 0} sqft</span>
                <span>{property.property_sub_type}</span>
                <span className='last'>{property.standard_status}</span>
              </ListingProps>

              <FavoriteWrapper>
                <FavoriteIcon
                  favorite={favorite}
                  loading={loading}
                  onClick={() =>
                    favorite
                      ? handleRemoveFromFavorites(property.listing_key)
                      : handleAddToFavorites(property.listing_key)
                  }
                />
              </FavoriteWrapper>
            </Col>
          </Row>
        </Container>
      </Wrapper>
      <ListingInfo>
        <Container>
          <Row>
            <ListingInfoCol>
              <ListingInfoTitle>subdivision</ListingInfoTitle>
              {property.subdivision_name}
            </ListingInfoCol>
            <ListingInfoCol>
              <ListingInfoTitle>days on market</ListingInfoTitle>
              {property.days_on_market}
            </ListingInfoCol>
            <ListingInfoCol className='no-border'>
              <ListingInfoTitle>original list price</ListingInfoTitle>
              {property.original_list_price &&
                `$${property.original_list_price?.toLocaleString()}`}
            </ListingInfoCol>
          </Row>
        </Container>
      </ListingInfo>
    </>
  );
}
