import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import {
  Estimation,
  ListingCompactView,
} from "../../../api/interfaces/listing";
import { InfoIcon } from "../InfoIcon";
import { InfoTooltip } from "../../components/InfoTooltip";

interface Props {
  estimation: Estimation;
  comparables: ListingCompactView[];
  comparablesMedian?: number;
  withEvaluations?: boolean;
}

const Title = styled.h2`
  text-transform: uppercase;
  color: #8c8c8c;
  font-size: 14px;
  line-height: 28px;

  &.subTitle {
    font-size: 12px;
    margin-bottom: 0px;
  }
`;

const EstimationsRow = styled(Row)`
  > div {
    text-align: center;
    font-size: 14px;
    align-self: center;
  }

  > div:nth-child(2) {
    font-size: 36px;
    line-height: 36px;
  }
  span {
    display: block;
    line-height: 14px;
    font-size: 14px;
  }
`;

const ColWithBorder = styled(Col)`
  border-left: 1px solid #e3e3e3;
  flex: 0 0 170px;
`;

function findMedian(arr: number[]) {
  arr.sort((a: number, b: number) => a - b);
  const middleIndex = Math.floor(arr.length / 2);

  if (arr.length % 2 === 0) {
    return (arr[middleIndex - 1] + arr[middleIndex]) / 2;
  } else {
    return arr[middleIndex];
  }
}

export function RentEstimations({
  estimation,
  withEvaluations,
  comparables,
}: Props) {
  let comparablesMedian: number | undefined = findMedian(
    comparables.map((x) => x.days_on_market)
  );

  comparablesMedian = Number.isNaN(comparablesMedian)
    ? undefined
    : comparablesMedian;

  const rent_prediction_min = estimation.rent_prediction_min
    ? `$${estimation.rent_prediction_min.toLocaleString()}`
    : "-";
  const rent_prediction = estimation.rent_prediction
    ? `$${estimation.rent_prediction.toLocaleString()}`
    : "-";
  const rent_prediction_max = estimation.rent_prediction_max
    ? `$${estimation.rent_prediction_max.toLocaleString()}`
    : "-";

  return (
    <Row>
      <Col>
        {withEvaluations && (
          <>
            <div>
              <Title className='estimationsTitle'>
                Rent est. per month{" "}
                <InfoIcon
                  placement='top'
                  label='Based on the rent comparables below'
                />
              </Title>
            </div>
            <div className='clear'></div>
          </>
        )}
        <EstimationsRow className='estimationRow'>
          <Col>
            {rent_prediction_min}
            <span>min</span>
          </Col>
          <Col>
            {withEvaluations && (
              <>
                {rent_prediction}
                <span>per month</span>
              </>
            )}

            {!withEvaluations && (
              <InfoTooltip
                placement='top'
                label={`Estimated vacancy: ${
                  comparablesMedian ? comparablesMedian.toFixed(0) : "unknown"
                }`}
              >
                {rent_prediction}
                <span>per month</span>
              </InfoTooltip>
            )}
          </Col>
          <Col>
            {rent_prediction_max}
            <span>max</span>
          </Col>
        </EstimationsRow>
      </Col>
      {withEvaluations && (
        <ColWithBorder>
          <Title className='subTitle'>
            Zipcode $/sqft{" "}
            <InfoIcon label='Average rent per square foot for closed listings within the same zipcode over the past 90 days.' />
          </Title>
          <div className='alignCenter'>
            {estimation.average_per_square_foot_in_area ?
              `$${parseFloat(
                estimation.average_per_square_foot_in_area
              ).toFixed(2)}` : 'N/A'}
          </div>

          <Title className='subTitle'>
            Estimated Vacancy{" "}
            <InfoIcon label='Estimated number of days of vacancy before leasing.' />
          </Title>
          <div className='alignCenter'>
            {comparablesMedian && comparablesMedian.toFixed(0)}
          </div>
        </ColWithBorder>
      )}
    </Row>
  );
}
