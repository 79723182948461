import { Image } from "react-bootstrap";
import { styled } from "styled-components";

const LogoCol = styled.div<{ $darkTheme?: boolean; }>`
  position: relative;
  font-size: 13px;
  color: ${props => props.$darkTheme ? "#fff" : "#333"};

  img {
    max-width: 150px;
  }
`;

interface Props {
  darkTheme: boolean
}

export const Header = (props: Props) => {
  return (
    <LogoCol $darkTheme={props.darkTheme}>
      <Image className='fleft' src={props.darkTheme ? '/images/terms_logo.png' : '/images/self-logo-black-transparent.png'} />
      <div className='fleft' style={{ paddingTop: 35 }}>
        Real estate, unbundled.
      </div>
      <div className='clear'></div>
    </LogoCol>
  );
};
