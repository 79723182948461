import Form from "react-bootstrap/esm/Form";
import styled from "styled-components";

interface Props {
  label: string;
  value: any;
  max: any;
  min: any;
  step?: any;
  formatFn: (value: any) => string;
  onChange: (value: any) => void;
}

const Wrapper = styled.div`
 
  & input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    height: 18px;
    width: 18px;
    background-color: #ffcf6f;
    border-radius: 50%;
    border: none;
    box-shadow: none;
    top: -4px;
    position: relative
  }
  & input[type="range"]::-webkit-slider-runnable-track {
    background: #fff;
    height: 3px;
  }
`;

export const Slider = (props: Props) => {
  return (
    <Wrapper className="sliderWrapper">
      <div>{props.label}</div>
      <Form.Range
        min={props.min}
        max={props.max}
        step={props.step}
        value={props.value}
        onChange={(e) => props.onChange(parseInt(e.target.value))}
      />
      <div>{props.formatFn(props.value)}</div>
    </Wrapper>
  );
};
