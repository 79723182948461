import styled from "styled-components";
import { Listing, ListingCompactView } from "../../../api/interfaces/listing";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useState } from "react";
import { useApi } from "../../../api/useApi";
import { InfoTooltip } from "../InfoTooltip";
import { FavoriteIcon } from "./FavoriteIcon";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  listing: ListingCompactView;
}

const Wrapper = styled.div`
  background: #e4e4e5;
  border-radius: 8px;
  height: 90px;
  position: relative;
  overflow: hidden;
`;

const Address = styled.div`
  color: #333333;
  font-weight: bold;
  font-size: 16px;
  margin-top: 10px;
`;
const MiddleRow = styled.div`
  color: #000000;
  font-size: 12px;

  span {
    
    float: right;
  }
`;

const ListingProps = styled.div`
  margin-top: 3px;
  font-size: 12px;
  span {
    color: #656f78;
    border-left: 1px solid #656f78;
    padding: 0px 5px;
  }
  span:first-child {
    padding-left: 0px;
    border: none;
  }
  span.last {
    float: right;
    padding: 0px;
    border: none;
  }
`;

const ImageWrapper = styled(Image)`
  width: 125px;
  height: 90px;
  object-fit: cover;
`;



export function ListingDetails({ listing }: Props) {
  const apiContext = useApi();
  const [loading, setLoading] = useState<boolean>(false);


  const property = listing;

  return (
    <>
      <Wrapper>
        <Container>
          <Row>
            <Col style={{ flex: "0 0 125px", paddingLeft: 0 }}>
              <ImageWrapper
                src={
                  property.media_url === ""
                    ? "/images/not-found.jpeg"
                    : property.media_url
                }
              />
            </Col>
            <Col>
              <Address>{property.full_address}</Address>
              <MiddleRow>
                {property.city}{", "}{property.state}{" "}{property.postal_code}
                <span>
                  <InfoTooltip
                    placement='left'
                    label='Last List Price'
                  >{`$${property.list_price.toLocaleString()}`}</InfoTooltip>
                </span>
              </MiddleRow>
              <ListingProps>
                <span>{property.bedrooms_total || 0} bed</span>
                <span>{property.bathrooms_total_decimal || 0} bath</span>
                <span>{property.living_area.toLocaleString() || 0} sqft</span>
                <span>{property.property_sub_type}</span>
                <span className='last'>{property.standard_status}</span>
              </ListingProps>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    </>
  );
}
