import { ReactNode } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/esm/types";

interface Props {
  label: string;
  placement?: Placement;
  show?: boolean;
  children: string | ReactNode;
}

export function InfoTooltip({ label, placement = "right", children, show }: Props) {
  return (
    <OverlayTrigger
      placement={placement}
      delay={{ show: 300, hide: 0 }}
      show={show}
      overlay={<Tooltip placement='right'>{label}</Tooltip>}
    >
      <div>{children}</div>
    </OverlayTrigger>
  );
}
