import { Form } from "react-bootstrap";
import { PriceInput } from "./PriceInput";
import { ProformaTable } from "./ProformaTable";
import { useEffect, useState } from "react";
import { InfoTooltip } from "../../../components/InfoTooltip";
import { USDollar } from "../../../utils";

interface Props {
  rentEstimate: number;
  vacancy: number;
  onChange: (income: number) => void;
  onRentChange: (rent: number) => void;
}

export const NetRentalIncome = (props: Props) => {
  const months = 12;
  const [enableVacancyCalculation, setEnableVacancyCalculation] =
    useState<boolean>(true);
  const [showOverlay, setShowOverlay] = useState<boolean|undefined>(true);
  const [rentEstimate, setRentEstimate] = useState<number>(props.rentEstimate);
  const [vacancyEstimate, setVacancyEstimate] = useState<number>(props.vacancy);

  useEffect(() => {
    if (!enableVacancyCalculation) {
      setVacancyEstimate(0);
    } else {
      setVacancyEstimate(props.vacancy);
    }
  }, [enableVacancyCalculation]);

  useEffect(() => {
    setVacancyEstimate(props.vacancy);
  }, [props.vacancy]);

  useEffect(() => {
    const annualRent = rentEstimate * months;
    const annualVacancy = annualRent * (vacancyEstimate / 365);
    props.onChange((annualRent - annualVacancy) / months);
    props.onRentChange(rentEstimate);
  }, [rentEstimate, vacancyEstimate, enableVacancyCalculation]);

  useEffect(() => {
    setTimeout(() => setShowOverlay(undefined), 3000)
  }, [])

  const annualRent = rentEstimate * months;
  const annualVacancy = annualRent * (vacancyEstimate / 365);

  return (
    <ProformaTable>
      <thead>
        <tr>
          <th className='w60' colSpan={3}>
            Rental Income
          </th>
          <th className='w20'>Monthly</th>
          <th className='w20'>Annual</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className='w60' colSpan={3}>
            <InfoTooltip label='Initial rent estimate sourced from Rent-O-Matic'>
              Rent Estimate
            </InfoTooltip>
          </td>
          <td className='w20' align='right'>
            <PriceInput value={rentEstimate} onUpdate={setRentEstimate} />
          </td>
          <td align='right' className='w20'>
            <div className='textOnly'>{USDollar.format(annualRent)}</div>
          </td>
        </tr>
        <tr>
          <td className='w30'>
            <InfoTooltip label="Enter the number of days you expect the property to be vacant each year.  Properties are often vacant during the period between tenants.  It's important to account for this in your analysis since you have carrying costs, usually in mortgage and property taxes.   if you have an existing tenant with a 12-month lease you can enter '0'.  You can minimize vacancy with more competitive pricing.">
              Vacancy Estimate
            </InfoTooltip>
          </td>
          <td className='w15' align='center'>
            {props.vacancy} days
          </td>
          <td className='w15' align='center'>
            <InfoTooltip show={showOverlay} label='Toggle expenses OFF > ON'>
              <Form.Check
                type='switch'
                defaultChecked={enableVacancyCalculation}
                onChange={(e) => setEnableVacancyCalculation(e.target.checked)}
              />
            </InfoTooltip>
          </td>
          <td align='right' className=' w20'>
            <div className='textOnly'>
              {" "}
              {USDollar.format(annualVacancy / months)}
            </div>
          </td>
          <td align='right' className=' w20'>
            <div className='textOnly'>{USDollar.format(annualVacancy)}</div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td className='w60' colSpan={3}>
            Net Rental Income
          </td>
          <td align='right' className='w20'>
            <div className='textOnly'>
              {USDollar.format(annualRent / months - annualVacancy / months)}
            </div>
          </td>
          <td align='right' className='w20'>
            <div className='textOnly'>
              {USDollar.format(annualRent - annualVacancy)}
            </div>
          </td>
        </tr>
      </tfoot>
    </ProformaTable>
  );
};
