import { createRoot } from "react-dom/client";
import App from "./ui/App";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";

import * as Sentry from "@sentry/react";
import { AuthProvider } from "./ui/hooks/useAuth";
import { ApiProvider } from "./api/useApi";
import { ListingProvider } from "./ui/hooks/useListing";

if (process.env.REACT_APP_DEBUG !== "true") {
  Sentry.init({
    dsn: "https://6bace72a505b1e7ba77476be04984c83@o4506855158513664.ingest.us.sentry.io/4506866216534016",
    tunnel: `${process.env.REACT_APP_API_URL}/tunnel/`,
  });
}

const webVersion = process.env.REACT_APP_WEB_ONLY === "true"

if ((webVersion && window === window.top) || (!webVersion && window !== window.top)) {
  const root = createRoot(document.getElementById("root")!);
  root.render(
    <AuthProvider>
      <ApiProvider>
        <ListingProvider>
          <App />
        </ListingProvider>
      </ApiProvider>
    </AuthProvider>
  );
}
