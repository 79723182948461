import { RouterProvider, createHashRouter } from "react-router-dom";

import {
  AccountsRoutes,
  Favorites,
  NotFoundRoutes,
  DashboardRoutes,
  DossierRoutes,
  CreamWrapper,
  HQRoutes,
  RentomaticWrapper,
  ProformaWrapper,
  HelpPage,
  SearchPage,
} from "./pages/";
import { LayoutView } from "./components";
import * as LayoutComponents from "./components/layout";

import { ContentWithSideBar } from "./components/layout";

function App() {
  const router = createHashRouter([
    {
      element: <LayoutView />,
      children: [
        {
          path: "/search",
          element: (
            <LayoutComponents.ProtectedRoute>
              <SearchPage />
            </LayoutComponents.ProtectedRoute>
          ),
        },
        {
          path: "/rentomatic/",
          children: [
            {
              path: ":listingString",
              element: (
                <LayoutComponents.ProtectedRoute>
                  <RentomaticWrapper />
                </LayoutComponents.ProtectedRoute>
              ),
            },
            {
              path: "",
              element: (
                <LayoutComponents.ProtectedRoute>
                  <RentomaticWrapper />
                </LayoutComponents.ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: "/proforma/",
          children: [
            {
              path: ":listingString",
              element: (
                <LayoutComponents.ProtectedRoute>
                  <ProformaWrapper />
                </LayoutComponents.ProtectedRoute>
              ),
            },
            {
              path: "",
              element: (
                <LayoutComponents.ProtectedRoute>
                  <ProformaWrapper />
                </LayoutComponents.ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: "/cream",
          element: (
            <LayoutComponents.ProtectedRoute>
              <CreamWrapper />
            </LayoutComponents.ProtectedRoute>
          ),
        },
        {
          path: "/hq",
          children: [
            {
              path: ":propertyId",
              element: (
                <LayoutComponents.ProtectedRoute>
                  <HQRoutes.PropertyView />
                </LayoutComponents.ProtectedRoute>
              ),
            },
            {
              path: "",
              element: (
                <LayoutComponents.ProtectedRoute>
                  <HQRoutes.PropertySearch />
                </LayoutComponents.ProtectedRoute>
              ),
            },
          ]
        },
        {
          path: "/dossier",
          element: (
            <LayoutComponents.ProtectedRoute>
              <DossierRoutes.DossierHome />
            </LayoutComponents.ProtectedRoute>
          ),
        },
        {
          path: "/favorites",
          element: (
            <LayoutComponents.ProtectedRoute>
              <Favorites />
            </LayoutComponents.ProtectedRoute>
          ),
        },
        {
          path: "/help",
          element: (
            <>
              <HelpPage />
            </>
          ),
        },
        {
          path: "/accounts",
          children: [
            {
              path: "profile",
              element: (
                <LayoutComponents.ProtectedRoute>
                  <AccountsRoutes.Profile />
                </LayoutComponents.ProtectedRoute>
              ),
            },
            {
              path: "reset",
              element: <AccountsRoutes.ForgotPassword />,
            },
            {
              path: "reset_confirm",
              element: <AccountsRoutes.ResetPassword />,
            },
            {
              path: "login",
              element: <AccountsRoutes.Login />,
            },
            {
              path: "logout",
              element: (
                <LayoutComponents.ProtectedRoute>
                  <AccountsRoutes.Logout />,
                </LayoutComponents.ProtectedRoute>
              ),
            },
            {
              path: "register",
              children: [
                {
                  path: "",
                  element: <AccountsRoutes.Register />,
                },
                {
                  path: "terms",
                  element: <AccountsRoutes.AcceptTerms />,
                },
                {
                  path: "confirmed",
                  element: <AccountsRoutes.RegisterConfirmation />,
                },
              ],
            },
          ],
        },
        {
          path: "/dashboard",
          children: [
            {
              path: "",
              element: (
                <LayoutComponents.ProtectedRoute>
                  <ContentWithSideBar
                    center={<DashboardRoutes.Dashboard />}
                    sidebar={<DashboardRoutes.PlanManagement />}
                  />
                </LayoutComponents.ProtectedRoute>
              ),
            },
            {
              path: "subscribe",
              element: (
                <LayoutComponents.ProtectedRoute>
                  <ContentWithSideBar
                    center={<DashboardRoutes.Dashboard />}
                    sidebar={<DashboardRoutes.Subscription />}
                  />
                </LayoutComponents.ProtectedRoute>
              ),
            },
            {
              path: "subscribe/success",
              element: (
                <LayoutComponents.ProtectedRoute>
                  <ContentWithSideBar
                    center={<DashboardRoutes.Dashboard />}
                    sidebar={<DashboardRoutes.FinishSubscription />}
                  />
                </LayoutComponents.ProtectedRoute>
              ),
            },
          ],
        },
        {
          path: "/not_found/search",
          element: <NotFoundRoutes.SearchPage />,
        },
        {
          path: "/not_found/apartments",
          element: <NotFoundRoutes.Apartments />,
        },
        {
          path: "/not_found/not_supported_type",
          element: <NotFoundRoutes.PropertyNotSupported />,
        },
        {
          path: "/not_found/not_supported_region",
          element: <NotFoundRoutes.RegionNotSupported />,
        },
        {
          path: "/not_found",
          element: (
            <LayoutComponents.ProtectedRoute>
              <NotFoundRoutes.Default />
            </LayoutComponents.ProtectedRoute>
          ),
        },
        {
          element: (
            <LayoutComponents.ProtectedRoute>
              <NotFoundRoutes.Default />
            </LayoutComponents.ProtectedRoute>
          ),
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
