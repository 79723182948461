import styled from "styled-components";
import { Image } from "react-bootstrap";

import { SearchTypeAhead } from "./components";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../../api/useApi";
import { Item } from "./components/SearchTypeAhead";
import { useEffect, useState } from "react";

const HQWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Logo = styled.div`
  width: 200px;
  margin: 80px 0px;
  img {
    width: 100%;
  }
`;

const Recent = styled.div`
  width: 350px;
  color: #fff;
  font-size: 16px;
  text-align: left;

  button {
    border: none;
    background: none;
    color: #fff;
    font-size: 14px;
    padding-left: 0;
    padding-right: 0;
  }
`

export const PropertySearch = () => {
  const navigate = useNavigate();
  const apiContext = useApi();
  const [options, setOptions] = useState<Item[]>([]);

  const viewProperty = (propertyId: string, address: string) => {
    navigate(`/hq/${propertyId}`, {
      state: { address },
    });
  };

  const getUserHistory = async () => {
    const results = await apiContext?.getSearchHistory();
    setOptions(results);
  };

  useEffect(() => {
    getUserHistory()
  }, [])

  return (
    <HQWrapper>
      <Logo>
        <Image src='/images/self-logo-white-transparent.png' />
      </Logo>
      <SearchTypeAhead onChange={viewProperty} />
      {options.length > 0 && <>
        <Recent className="mt-3">
          <div className="mb-2">Recent searches</div>
          {options.map(x => <button onClick={() => viewProperty(x.v1PropertyId, x.address)}>{x.address}</button>)}  
        </Recent>
      </>}
      
    </HQWrapper>
  );
};
