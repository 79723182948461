import { SplashHeader, SplashSetup, SplashAbout } from "./Splash";

export const HelpPage = () => {
  return (
    <div className="mainCol">
      <SplashHeader />
      <SplashSetup />
      <SplashAbout />
    </div>
  );
};
