import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Heart, User, LogOut, HelpCircle } from "react-feather";
import { useApi } from "../../../api/useApi";

export const Navigation = () => {
  const context = useApi();
  const [user, setUser] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await context?.getUserData();
        setUser(`${data?.first_name} ${data?.last_name ? data?.last_name[0] : ''}`);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData().catch(console.error);
  }, []);

  if (user === null) {
    return null;
  }

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle>
          {user} <User />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href='/#/favorites'>Favorites <Heart/></Dropdown.Item>
          <Dropdown.Item href='/#/accounts/profile'>Profile <User /></Dropdown.Item>
          <Dropdown.Item href='/#/help'>Installation <HelpCircle /></Dropdown.Item>
          <Dropdown.Item href='/#/accounts/logout'>Logout <LogOut /></Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
