import styled from "styled-components";
import { Listing } from "../../../../api/interfaces/listing";
import { USDollar } from "../../../utils";

interface Props {
  listing?: Listing;
  creamScore?: number | null
}

const Wrapper = styled.div`
  height: 300px;
  border: 1px solid #d4d4f9;
  padding: 10px 0px;
  color: #fff;
  overflow-y: scroll;
  margin-top: 5px;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  padding: 0px 12px;
  min-height: 50px;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
`;

const Description = styled.div`
  padding: 10px 12px;
  font-size: 10px;
  font-weight: bold;

  .largeFont {
    font-size: 12px;
  }
`;

export const SelectedProperty = (props: Props) => {
  const { listing, creamScore } = props;
  if (!listing) {
    return (
      <Wrapper>
        <Title>None selected</Title>
      </Wrapper>
    );
  }
  return (
    <>
      <b>SELECTED PROPERTY</b>
      {creamScore && creamScore >= 0 && <div className="fright">CREAM Score: {creamScore}</div>}
      <div className="clear"></div>
      <Wrapper>
        <Title>
          {listing.full_address}
          <br />
          {listing.city}, {listing.state} {listing.postal_code}
        </Title>
        <Description>
          <div className='largeFont'>Status: {listing.standard_status}</div>
          <div className='largeFont'>Community: {listing.subdivision_name}</div>
          <div className='largeFont'>Living Area: {listing.living_area}</div>
          <div className='largeFont'>Bedrooms: {listing.bedrooms_total}</div>
          <div className='largeFont'>
            Baths: {listing.bathrooms_total_decimal}
          </div>
          <div className='largeFont'>Original List Price: {listing.original_list_price ? USDollar.format(listing.original_list_price) : ''}</div>
          <div className='largeFont'>
            Days on Market: {listing.days_on_market}
          </div>
          <br />
          <div>Source: {listing.data_source}</div>
          <div>MLS: {listing.listing_id}</div>

          {listing.last_modified_date && (
            <div>
              Last Changed:{" "}
              {new Date(listing.last_modified_date).toLocaleDateString("en-US")}
            </div>
          )}
          <div>Listed By: {listing.list_agent_full_name}</div>
          <div>Brokered By: {listing.list_office_name}</div>
          <div>Based on information from {listing.data_source} for the period through {new Date(listing.updated_at).toLocaleDateString("en-US", )}. Neither the Board nor {listing.data_source} guarantees or is in any way responsible for its accuracy. All data is provided “AS IS” and with all faults</div>
        </Description>
      </Wrapper>
    </>
  );
};
