import { Col, Image, Row } from "react-bootstrap";
import styled from "styled-components";
import { CTABtn } from "../../../components/listing/CTABtn";

const Wrapper = styled.div`
  background: #42465d;
  border-radius: 8px;
  border-color: #c8c8ec;
  border-style: solid;
  padding: 13px;
`;

const ImageCol = styled(Col)`
  flex: 0 0 85px;
`;

const BtnCol = styled(Col)`
  align-content: space-evenly;
`;

const Preview = styled.div`
  font-size: 12px;
  font-weight: 100;
  line-height: 12px;
  color: white;
`;

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  enabled: boolean;
  listingKey: string;
  estimatedReturn?: number;
}

export const DossierWidget = (props: Props) => {
  return (
    <Wrapper className={props.className}>
      <Row>
        <ImageCol>
          <Image rounded src='/images/module_icons/dossier.png' width={85} />
        </ImageCol>
        <BtnCol>
          <Preview className='mb-2'>
            Confirm ownership, listing history, and more...
          </Preview>
          <CTABtn enabled={props.enabled} url="/dossier" title="GET DOCUMENTS" />
        </BtnCol>
      </Row>
    </Wrapper>
  );
};
